import React from "react";
import "../CSS/login.css";
import { Button, Col, Container, Form } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { apiCall } from "../Services/apiCall";
import logopic from "../img/logosecured2.svg"

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const { isSuccess, data } = await apiCall(
      `http://localhost:3050/v1.0/auth/login`,
      {},
      {
        email: email,
        password: password,
      },
      "POST"
    );

    if (isSuccess) {
      localStorage.setItem("userData", JSON.stringify(data.data));
      // localStorage.setItem("data", data);
      // localStorage.setItem("password", password);
      navigate("/dashboard");
    }
  };
  return (
    <div className="logins_forms">
      <Container>
        <div className="loginform">
          <img src={logopic} alt="" />
          {/* <h4 className="m-0">Secured365</h4> */}
          <h4 className="mt-2">Sign In</h4>
          <div>
            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <div className="hstack justify-content-between">
              <Form.Group className="mb-3">
                <Form.Check required label="Remember Me" />
              </Form.Group>
              <Form.Group className="mb-3">
                <NavLink to={"#"}>Forget Your Password</NavLink>
              </Form.Group>
            </div>
            {error && <p className="error-message">{error}</p>}
            {/* <NavLink to={"/dashboard"}> */}
            <Button
              type="submit"
              className="button_types"
              onClick={(e) => handleLogin(e)}
            >
              Sign In
            </Button>
            {/* </NavLink> */}
          </div>
        </div>
      </Container>
    </div>
  );
};
export default LoginPage;
