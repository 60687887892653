import React, { useState } from "react";
import "../CSS/morepage.css";
import { Col, Container, Row } from "react-bootstrap";
import ClientInfoDetails from "../Component/clientInfoDetails";
import BorrowerInfoDetails from "../Component/borrowerInfo";
import CompanyInfoDetails from "../Component/companyInfo";
const AllFormEditDataDetails = () => {
  const AllFormEdits = [
    {
      id: 1,
      heading: "Templates",
      data: [
        {
          id: 1,
          heading: "Full Templates",
          data: [
            {
              id: 1,
              heading: "Two Column Form",
            },
            {
              id: 2,
              heading: "One Column Head/Two Column Body",
            },
            {
              id: 3,
              heading: "Two Column Head/One Column Body",
            },
            {
              id: 4,
              heading: "Three Column Form",
            },
          ],
        },
        {
          id: 2,
          heading: "Partial Templates",
          data: [
            {
              id: 1,
              heading: "Client Info",
              data: [
                {
                  id: 1,
                  heading: "Addressing To",
                },
                {
                  id: 2,
                  heading: "Client Name",
                },
                {
                  id: 3,
                  heading: "Address",
                },
                {
                  id: 4,
                  heading: "Client State",
                },
                {
                  id: 5,
                  heading: "Client City",
                },
                {
                  id: 6,
                  heading: "Zip",
                },
                {
                  id: 7,
                  heading: "Country",
                },
                {
                  id: 8,
                  heading: "Phone",
                },
                {
                  id: 9,
                  heading: "Fax",
                },
              ],
            },
            {
              id: 2,
              heading: "Borrower Info",
              data: [
                {
                  addressingTo: "text",
                  borrowerFirstName: "text",
                  borrowerMiddleName: "text",
                  borrowerLastName: "text",
                  address: "text",
                  clientState: "text",
                  clientCity: "text",
                  zip: "text",
                  country: "text",
                  phone: "text",
                  fax: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "Company Info",
              data: [
                {
                  companyName: "text",
                  address: "text",
                  clientState: "text",
                  clientCity: "text",
                  zip: "text",
                  country: "text",
                  phone: "text",
                  fax: "text",
                  STATELICNUM: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "Vehicle Info Block",
              data: [
                {
                  vehical: "text",
                  vehicalVin: "text",
                  plate: "text",
                  color: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Case Reference Info",
              data: [
                {
                  date: "date",
                  caseNumber: "text",
                  referenceNumber: "text",
                  accountNumber: "text",
                },
              ],
            },
            {
              id: 6,
              heading: "Cosigner Info Block",
              data: [
                {
                  cosignerFirstName: "text",
                  cosignerMiddleName: "text",
                  cosignerLastName: "text",
                  cosignAddress: "text",
                  cosignCity: "text",
                  cosignState: "text",
                  cosignZip: "text",
                  cosignPhone: "text",
                  cosignCell: "text",
                },
              ],
            },
            {
              id: 7,
              heading: "2 Column Block Logo on Left",
              data: [
                {
                  logoSize: "text",
                  logo: "logo",
                },
              ],
            },
            {
              id: 8,
              heading: "2 Column Block Logo on Right",
              data: [
                {
                  logoSize: "text",
                  logo: "logo",
                },
              ],
            },
            {
              id: 9,
              heading: "Adjuster Info",
              data: [
                {
                  to: "text",
                  agentAddress: "text",
                  agentCity: "text",
                  agentState: "text",
                  agentZip: "text",
                  agentPhone: "text",
                  agentFax: "text",
                },
              ],
            },
            {
              id: 10,
              heading: "Addresses",
              data: [
                {
                  addresses: "text",
                },
              ],
            },
            {
              id: 11,
              heading: "Recovery Location",
              data: [
                {
                  recoveryLocation: "text",
                  recoveryCity: "text",
                  recoveryState: "text",
                  recoveryZip: "text",
                },
              ],
            },
          ],
        },
        {
          id: 3,
          heading: "Elements",
          data: [
            {
              id: 1,
              heading: "Signature line",
              data: [
                {
                  signatureLine: "text",
                  descriptionBelowTheLine: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Write in line",
              data: [
                {
                  signatureLine: "text",
                  descriptionAboveTheLine: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "Checkbox",
              data: [
                {
                  checkbox: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "Comment Area",
              data: [
                {
                  commentArea: "text",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      id: 2,
      heading: "Template Keys ",
      data: [
        {
          id: 1,
          heading: "Company Specific",
          data: [
            {
              id: 1,
              heading: "Company Name",
              data: [
                {
                  name: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Address",
              data: [
                {
                  address: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "City",
              data: [
                {
                  city: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "State",
              data: [
                {
                  state: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Zip",
              data: [
                {
                  zip: "text",
                },
              ],
            },
            {
              id: 6,
              heading: "Phone",
              data: [
                {
                  phone: "text",
                },
              ],
            },
            {
              id: 7,
              heading: "Fax",
              data: [
                {
                  fax: "text",
                },
              ],
            },
            {
              id: 8,
              heading: "Logo(Large)",
              data: [
                {
                  logo: "logo",
                },
              ],
            },
            {
              id: 9,
              heading: "Logo(Medium)",
              data: [
                {
                  logo: "logo",
                },
              ],
            },
            {
              id: 10,
              heading: "Logo(Small)",
              data: [
                {
                  logo: "logo",
                },
              ],
            },
            {
              id: 11,
              heading: "Company Additional Information on case",
              data: [
                {
                  companyAdditionalInformationOnCase: "text",
                },
              ],
            },
            {
              id: 12,
              heading: "Skip Number",
              data: [
                {
                  skipNumber: "text",
                },
              ],
            },
            {
              id: 13,
              heading: "State License Number",
              data: [
                {
                  skipLicenceNumber: "text",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          heading: "Agent Primary",
          data: [
            {
              id: 1,
              heading: "Name",
              data: [
                {
                  name: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Address",
              data: [
                {
                  address: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "City",
              data: [
                {
                  city: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "State",
              data: [
                {
                  state: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Zip",
              data: [
                {
                  zip: "text",
                },
              ],
            },
            {
              id: 6,
              heading: "Phone",
              data: [
                {
                  phone: "text",
                },
              ],
            },
            {
              id: 7,
              heading: "Fax",
              data: [
                {
                  fax: "text",
                },
              ],
            },
            {
              id: 8,
              heading: "Close Fee",
              data: [
                {
                  closeFee: "text",
                },
              ],
            },
            {
              id: 9,
              heading: "Repo Fee",
              data: [
                {
                  repoFee: "text",
                },
              ],
            },
            {
              id: 10,
              heading: "Vol Fee",
              data: [
                {
                  volFee: "text",
                },
              ],
            },
            {
              id: 11,
              heading: "Storage Fee",
              data: [
                {
                  storageFee: "text",
                },
              ],
            },
            {
              id: 12,
              heading: "Free days",
              data: [
                {
                  freeDays: "text",
                },
              ],
            },
            {
              id: 13,
              heading: "Instructions",
              data: [
                {
                  instructions: "text",
                },
              ],
            },
            {
              id: 14,
              heading: "Additional Info",
              data: [
                {
                  additionalInfo: "text",
                },
              ],
            },
          ],
        },
        {
          id: 3,
          heading: "Agent Secondary",
          data: [
            {
              id: 1,
              heading: " Adjuster Name",
              data: [
                {
                  adjusterName: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Address",
              data: [
                {
                  address: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "City",
              data: [
                {
                  city: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "State",
              data: [
                {
                  state: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Zip",
              data: [
                {
                  zip: "text",
                },
              ],
            },
            {
              id: 6,
              heading: "Phone",
              data: [
                {
                  phone: "text",
                },
              ],
            },
            {
              id: 7,
              heading: "Fax",
              data: [
                {
                  fax: "text",
                },
              ],
            },
            {
              id: 8,
              heading: "Close Fee",
              data: [
                {
                  closefee: "text",
                },
              ],
            },
            {
              id: 9,
              heading: "Repo Fee",
              data: [
                {
                  repoFee: "text",
                },
              ],
            },
            {
              id: 10,
              heading: "Vol Fee",
              data: [
                {
                  volFee: "text",
                },
              ],
            },
            {
              id: 11,
              heading: "Storage Fee",
              data: [
                {
                  storageFee: "text",
                },
              ],
            },
            {
              id: 12,
              heading: "Free days",
              data: [
                {
                  freeDays: "text",
                },
              ],
            },
            {
              id: 13,
              heading: "Instructions",
              data: [
                {
                  instructions: "text",
                },
              ],
            },
            {
              id: 14,
              heading: "Additional Info",
              data: [
                {
                  additionalInfo: "text",
                },
              ],
            },
          ],
        },
        {
          id: 4,
          heading: "Borrower",
          data: [
            {
              id: 1,
              heading: "Borrower's First Name",
              data: [
                {
                  borrowerFirstName: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Borrower's Middle Initial",
              data: [
                {
                  borrowerMiddleName: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "Borrower's Last Name",
              data: [
                {
                  borrowerLastName: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "Last Four of Borrower's SSN",
              data: [
                {
                  lastFourOfBorrowerSSN: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Borrower's Address",
              data: [
                {
                  borrowerAddress: "text",
                },
              ],
            },
            {
              id: 6,
              heading: "Borrower's Unit ",
              data: [
                {
                  borrowerUnit: "text",
                },
              ],
            },
            {
              id: 7,
              heading: "Borrower's City",
              data: [
                {
                  borrowerCity: "text",
                },
              ],
            },
            {
              id: 8,
              heading: "Borrower's State",
              data: [
                {
                  borrowerState: "text",
                },
              ],
            },
            {
              id: 9,
              heading: "Borrower's Zip",
              data: [
                {
                  borrowerZip: "text",
                },
              ],
            },
            {
              id: 10,
              heading: "Borrower's Phone",
              data: [
                {
                  borrowerPhone: "text",
                },
              ],
            },
            {
              id: 11,
              heading: "Borrower's Cell Phone",
              data: [
                {
                  borrowerCellPhone: "text",
                },
              ],
            },
            {
              id: 12,
              heading: "Borrower's DOB",
              data: [
                {
                  borrowerDOB: "date",
                },
              ],
            },
            {
              id: 13,
              heading: "QR Code with Basic Borrower Info",
              data: [
                {
                  qRCodeWithBasicBorrowerInfo: "text",
                },
              ],
            },
            {
              id: 14,
              heading: "Borrower's POE's name",
              data: [
                {
                  borrowerPOEName: "text",
                },
              ],
            },
            {
              id: 15,
              heading: "Borrower's POE Address",
              data: [
                {
                  borrowerPOEAddress: "text",
                },
              ],
            },
            {
              id: 16,
              heading: "POE City",
              data: [
                {
                  poeCity: "text",
                },
              ],
            },
            {
              id: 17,
              heading: "POE State",
              data: [
                {
                  poeState: "text",
                },
              ],
            },
            {
              id: 18,
              heading: "POE Zip",
              data: [
                {
                  poeZip: "text",
                },
              ],
            },
            {
              id: 19,
              heading: "POE Phone",
              data: [
                {
                  poePhone: "text",
                },
              ],
            },
            {
              id: 20,
              heading: "Landlord's Name",
              data: [
                {
                  landlordName: "text",
                },
              ],
            },
            {
              id: 21,
              heading: "Landlord's Address",
              data: [
                {
                  landlordAddress: "text",
                },
              ],
            },
            {
              id: 22,
              heading: "Landlord's City",
              data: [
                {
                  landlordCity: "text",
                },
              ],
            },
            {
              id: 23,
              heading: "Landlord's State",
              data: [
                {
                  landlordState: "text",
                },
              ],
            },
            {
              id: 24,
              heading: "Landlord's Zip",
              data: [
                {
                  landlordZip: "text",
                },
              ],
            },
            {
              id: 25,
              heading: "Landlord's Phone",
              data: [
                {
                  landlordPhone: "text",
                },
              ],
            },
            {
              id: 26,
              heading: "Landlord's State",
              data: [
                {
                  landlordState: "text",
                },
              ],
            },
          ],
        },
        {
          id: 5,
          heading: "Branch",
          data: [
            {
              id: 1,
              heading: "Name",
              data: [
                {
                  name: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Address",
              data: [
                {
                  address: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "City",
              data: [
                {
                  city: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "State",
              data: [
                {
                  state: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Zip",
              data: [
                {
                  heading: "text",
                },
              ],
            },
            {
              id: 6,
              heading: "Phone",
              data: [
                {
                  phone: "text",
                },
              ],
            },
            {
              id: 7,
              heading: "Fax",
              data: [
                {
                  fax: "text",
                },
              ],
            },
          ],
        },
        {
          id: 6,
          heading: "Cosigner",
          data: [
            {
              id: 1,
              heading: "Cosigner's First Name",
              data: [
                {
                  CosignersFirstName: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Co Signer's Middle Initial",
              data: [
                {
                  CosignersMIddleName: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "Co Signer's Last Name",
              data: [
                {
                  CosignersLastName: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "SSN Last 4",
              data: [
                {
                  SNNLast: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Co Signer's Address",
              data: [
                {
                  CosignersAddress: "text",
                },
              ],
            },
            {
              id: 6,
              heading: "Co signer's Unit",
              data: [
                {
                  CosignersUnit: "text",
                },
              ],
            },
            {
              id: 7,
              heading: "City",
              data: [
                {
                  city: "text",
                },
              ],
            },
            {
              id: 8,
              heading: "State",
              data: [
                {
                  state: "text",
                },
              ],
            },
            {
              id: 9,
              heading: "Zip",
              data: [
                {
                  zip: "text",
                },
              ],
            },
            {
              id: 10,
              heading: "Phone",
              data: [
                {
                  phone: "text",
                },
              ],
            },
            {
              id: 11,
              heading: "Cell Phone",
              data: [
                {
                  cellPhone: "text",
                },
              ],
            },
            {
              id: 12,
              heading: "Co Signer's POE Address",
              data: [
                {
                  coSignerPOEAddress: "text",
                },
              ],
            },
            {
              id: 13,
              heading: "POE City",
              data: [
                {
                  POECity: "text",
                },
              ],
            },
            {
              id: 14,
              heading: "POE State",
              data: [
                {
                  POEState: "text",
                },
              ],
            },
            {
              id: 15,
              heading: "POE Zip",
              data: [
                {
                  POEZip: "text",
                },
              ],
            },
            {
              id: 16,
              heading: "POE Phone",
              data: [
                {
                  POEPhone: "text",
                },
              ],
            },
            {
              id: 17,
              heading: "POE Name",
              data: [
                {
                  POEName: "text",
                },
              ],
            },
          ],
        },
        {
          id: 7,
          heading: "Case Specific",
          data: [
            {
              id: 1,
              heading: "Case Number",
              data: [
                {
                  caseNumber: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "QR Code with Case number",
              data: [
                {
                  QrCodeWithCaseNumber: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "Reference Number",
              data: [
                {
                  referenceNumber: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "Date of Order",
              data: [
                {
                  dateOfOrder: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Expiration Date",
              data: [
                {
                  expirationDate: "date",
                },
              ],
            },
            {
              id: 6,
              heading: "Order Type",
              data: [
                {
                  orderTye: "text",
                },
              ],
            },
            {
              id: 7,
              heading: "Addresses [Large]",
              data: [
                {
                  addressLarge: "text",
                },
              ],
            },
            {
              id: 8,
              heading: "Addresses [Regular]",
              data: [
                {
                  addressRegular: "text",
                },
              ],
            },
            {
              id: 9,
              heading: "Addresses [No Phone]",
              data: [
                {
                  addressNoPhone: "text",
                },
              ],
            },
            {
              id: 10,
              heading: "Case Status",
              data: [
                {
                  caseStatus: "text",
                },
              ],
            },
            {
              id: 11,
              heading: "Case Sub-status",
              data: [
                {
                  caseSubStatus: "text",
                },
              ],
            },
            {
              id: 12,
              heading: "Close Date",
              data: [
                {
                  closeDate: "date",
                },
              ],
            },
            {
              id: 13,
              heading: "Additional Collateral",
              data: [
                {
                  AdditionalCollateral: "text",
                },
              ],
            },
            {
              id: 14,
              heading: "Forwarding Number ",
              data: [
                {
                  forwardingNumber: "text",
                },
              ],
            },
            {
              id: 15,
              heading: "Pictures",
              data: [
                {
                  pictures: "url",
                },
              ],
            },
            {
              id: 16,
              heading: "All Adjusters",
              data: [
                {
                  allAdjusters: "text",
                },
              ],
            },
            {
              id: 17,
              heading: "Permanent Banner",
              data: [
                {
                  permanentBanner: "text",
                },
              ],
            },
          ],
        },
        {
          id: 8,
          heading: "Case Worker",
          data: [
            {
              id: 1,
              heading: "First Name",
              data: [
                {
                  firstName: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Last Name",
              data: [
                {
                  lastName: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "Email Address",
              data: [
                {
                  emailAddress: "text",
                },
              ],
            },
          ],
        },
        {
          id: 9,
          heading: "Loan Specific",
          data: [
            {
              id: 1,
              heading: "Monthly Payments",
              data: [
                {
                  monthalyPayment: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Delinquent Since",
              data: [
                {
                  delinquentSince: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "Balance on Account",
              data: [
                {
                  balanceOnAccount: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "Amount Past Due",
              data: [
                {
                  amountPastDue: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Bankruptcy Type",
              data: [
                {
                  bankruptcy: "text",
                },
              ],
            },
          ],
        },
        {
          id: 10,
          heading: "Client Specific",
          data: [
            {
              id: 1,
              heading: "Client Account Number",
              data: [
                {
                  cityAccountNumber: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Client Name",
              data: [
                {
                  clientName: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "Client Address",
              data: [
                {
                  clientAddress: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "Client City",
              data: [
                {
                  clientCity: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Client State",
              data: [
                {
                  clientState: "text",
                },
              ],
            },
            {
              id: 6,
              heading: "Client Zip",
              data: [
                {
                  clientZip: "text",
                },
              ],
            },
            {
              id: 7,
              heading: "Client Billing Address",
              data: [
                {
                  clientBittingAddress: "text",
                },
              ],
            },
            {
              id: 8,
              heading: "Client Phone",
              data: [
                {
                  clientPhone: "text",
                },
              ],
            },
            {
              id: 9,
              heading: "Client Fax",
              data: [
                {
                  clientFax: "text",
                },
              ],
            },
            {
              id: 10,
              heading: "Client Toll Free",
              data: [
                {
                  clientTollFree: "text",
                },
              ],
            },
            {
              id: 11,
              heading: "Client Branch",
              data: [
                {
                  clientBranch: "text",
                },
              ],
            },
            {
              id: 12,
              heading: "Client Branch Phone",
              data: [
                {
                  clientBranchPhone: "text",
                },
              ],
            },
            {
              id: 13,
              heading: "Assignee's Name",
              data: [
                {
                  assignName: "text",
                },
              ],
            },
            {
              id: 14,
              heading: "Assignee's Ext",
              data: [
                {
                  assignExt: "text",
                },
              ],
            },
            {
              id: 15,
              heading: "Assignee's Phone",
              data: [
                {
                  assignPhone: "text",
                },
              ],
            },
            {
              id: 16,
              heading: "Assignee's Fax",
              data: [
                {
                  assignFax: "text",
                },
              ],
            },
          ],
        },
        {
          id: 11,
          heading: "Lien Holder",
          data: [
            {
              id: 1,
              heading: "Lien Holder",
              data: [
                {
                  lienHolder: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Lien Holder Address",
              data: [
                {
                  lienHolderAddress: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "Lien Holder City",
              data: [
                {
                  lienHolderCity: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "Lien Holder State",
              data: [
                {
                  lienHolderState: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Lien Holder Zip",
              data: [
                {
                  lienHolderZip: "text",
                },
              ],
            },
            {
              id: 6,
              heading: "Lien Holder Phone",
              data: [
                {
                  lienHolderPhone: "text",
                },
              ],
            },
          ],
        },
        {
          id: 12,
          heading: "Vehical Specific",
          data: [
            {
              id: 1,
              heading: "Vehicle Year",
              data: [
                {
                  vehicalYear: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Vehicle Make",
              data: [
                {
                  vehicalMake: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "Vehicle Model",
              data: [
                {
                  vehicalModel: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "Vehicle License Plate",
              data: [
                {
                  vehicalLicencePlate: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Vehicle Color",
              data: [
                {
                  vehicalColor: "text",
                },
              ],
            },
            {
              id: 6,
              heading: "Vehicle License State",
              data: [
                {
                  vehicalLicenceState: "text",
                },
              ],
            },
            {
              id: 7,
              heading: "Vehicle Identification Number",
              data: [
                {
                  vehicalIdentificationNumber: "text",
                },
              ],
            },
            {
              id: 8,
              heading: "Barcode with VIN",
              data: [
                {
                  barcodeWithVIN: "text",
                },
              ],
            },
            {
              id: 9,
              heading: "QR Code with VIN",
              data: [
                {
                  QRCodeWithVIN: "text",
                },
              ],
            },
            {
              id: 10,
              heading: "VIN Last 6",
              data: [
                {
                  VINLast6: "text",
                },
              ],
            },
            {
              id: 11,
              heading: "Key Code 1",
              data: [
                {
                  keyCode1: "text",
                },
              ],
            },
            {
              id: 12,
              heading: "Key Code 2",
              data: [
                {
                  KeyCode2: "text",
                },
              ],
            },
            {
              id: 13,
              heading: "License Expiration",
              data: [
                {
                  licence: "text",
                },
              ],
            },
            {
              id: 14,
              heading: "Vehicle Mileage",
              data: [
                {
                  vehicalMileage: "text",
                },
              ],
            },
            {
              id: 15,
              heading: "Estimated Damage",
              data: [
                {
                  estimatedDamage: "text",
                },
              ],
            },
            {
              id: 16,
              heading: "Vehicle Dealer",
              data: [
                {
                  vehicalDealer: "text",
                },
              ],
            },
          ],
        },
        {
          id: 13,
          heading: "Recovery Specific",
          data: [
            {
              id: 1,
              heading: "Recovery Agent(s)",
              data: [
                {
                  recoveryAgent: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Recovery Agent Address",
              data: [
                {
                  recoveryAgentAddress: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "Recovery Agent Cell",
              data: [
                {
                  recoveryAgentCell: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "Recovery Agent Fax",
              data: [
                {
                  recoveryAgentFax: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Recovery Agent Phone",
              data: [
                {
                  recoveryAgentPhone: "text",
                },
              ],
            },
            {
              id: 6,
              heading: "Release To",
              data: [
                {
                  releaseTo: "text",
                },
              ],
            },
            {
              id: 7,
              heading: "Transport Company",
              data: [
                {
                  transportCompany: "text",
                },
              ],
            },
            {
              id: 8,
              heading: "Case Form Notes",
              data: [
                {
                  caseFormNotes: "text",
                },
              ],
            },
            {
              id: 9,
              heading: "Close Reason",
              data: [
                {
                  closeReason: "text",
                },
              ],
            },
            {
              id: 10,
              heading: "On Hold Reason",
              data: [
                {
                  onHoldReason: "text",
                },
              ],
            },
            {
              id: 11,
              heading: "Key Status",
              data: [
                {
                  keyStatus: "text",
                },
              ],
            },
            {
              id: 12,
              heading: "Personals Items",
              data: [
                {
                  personalItems: "text",
                },
              ],
            },
            {
              id: 13,
              heading: "Recovery Address",
              data: [
                {
                  recoveryAddress: "text",
                },
              ],
            },
            {
              id: 14,
              heading: "Recovery City",
              data: [
                {
                  recoveryCity: "text",
                },
              ],
            },
            {
              id: 15,
              heading: "Recovery State",
              data: [
                {
                  recoveryState: "text",
                },
              ],
            },
            {
              id: 16,
              heading: "Recovery Zip",
              data: [
                {
                  recoveryZip: "text",
                },
              ],
            },
            {
              id: 17,
              heading: "Recovery Date",
              data: [
                {
                  recoveryDate: "date",
                },
              ],
            },
            {
              id: 18,
              heading: "Recovery Time",
              data: [
                {
                  recoveryTime: "time",
                },
              ],
            },
            {
              id: 19,
              heading: "Recovery Fee",
              data: [
                {
                  recoveryFee: "text",
                },
              ],
            },
            {
              id: 20,
              heading: "Storage Location Address",
              data: [
                {
                  storageLocationAddress: "text",
                },
              ],
            },
            {
              id: 21,
              heading: "Storage Location City",
              data: [
                {
                  storageLocationCity: "text",
                },
              ],
            },
            {
              id: 22,
              heading: "Storage Location State",
              data: [
                {
                  storageLocationState: "text",
                },
              ],
            },
            {
              id: 23,
              heading: "Storage Location Space/Lot",
              data: [
                {
                  storageLocationSpace: "text",
                },
              ],
            },
            {
              id: 24,
              heading: "Storage Location Phone",
              data: [
                {
                  storageLocationPhone: "text",
                },
              ],
            },
            {
              id: 25,
              heading: "Personal Property Notes",
              data: [
                {
                  personalPropertyNotes: "text",
                },
              ],
            },
            {
              id: 26,
              heading: "Personal Property Release Date",
              data: [
                {
                  personalPropertyReleaseDate: "date",
                },
              ],
            },
            {
              id: 27,
              heading: "Damage",
              data: [
                {
                  damage: "text",
                },
              ],
            },
            {
              id: 28,
              heading: "Personals (Yes / No)",
              data: [
                {
                  personal: "text",
                },
              ],
            },
            {
              id: 29,
              heading: "Driveable (Yes / No)",
              data: [
                {
                  driveable: "text",
                },
              ],
            },
            {
              id: 30,
              heading: "DMV Name",
              data: [
                {
                  DMVName: "text",
                },
              ],
            },
            {
              id: 31,
              heading: "DMV Address",
              data: [
                {
                  DMVAddress: "text",
                },
              ],
            },
            {
              id: 32,
              heading: "DMV City",
              data: [
                {
                  DMVCity: "text",
                },
              ],
            },
            {
              id: 33,
              heading: "DMV State",
              data: [
                {
                  DMWState: "text",
                },
              ],
            },
            {
              id: 34,
              heading: "DMV Zip Code",
              data: [
                {
                  DMWZipCode: "text",
                },
              ],
            },
            {
              id: 35,
              heading: "DMV Phone",
              data: [
                {
                  DMWPhone: "text",
                },
              ],
            },
          ],
        },
        {
          id: 14,
          heading: "Recovery Police",
          data: [
            {
              id: 1,
              heading: "Police Agency",
              data: [
                {
                  policeAgency: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Police Branch",
              data: [
                {
                  policeBranch: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "Police Badge #",
              data: [
                {
                  policeBadge: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "Police Report #",
              data: [
                {
                  policeReport: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Police Address",
              data: [
                {
                  policeAddress: "text",
                },
              ],
            },
            {
              id: 6,
              heading: "Police City",
              data: [
                {
                  policeCity: "text",
                },
              ],
            },
            {
              id: 7,
              heading: "Police State",
              data: [
                {
                  policeState: "text",
                },
              ],
            },
            {
              id: 8,
              heading: "Police Zip",
              data: [
                {
                  policeZip: "text",
                },
              ],
            },
            {
              id: 9,
              heading: "Police Phone",
              data: [
                {
                  policePhone: "text",
                },
              ],
            },
            {
              id: 10,
              heading: "Police Fax",
              data: [
                {
                  policeFax: "text",
                },
              ],
            },
          ],
        },
        {
          id: 15,
          heading: "Release Specific",
          data: [
            {
              id: 1,
              heading: "Recovery Agent(s)",
              data: [
                {
                  recoveryAgent: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Personal Handling Fees",
              data: [
                {
                  personalHandlingFee: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "Personals Storage Per Day",
              data: [
                {
                  personalStoragePerDay: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "Vehicle Storage Per Day",
              data: [
                {
                  vehicalStoragePerDay: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Disposal Days",
              data: [
                {
                  disposalDays: "text",
                },
              ],
            },
            {
              id: 6,
              heading: "Total Personals Due",
              data: [
                {
                  totalPersonalDue: "text",
                },
              ],
            },
            {
              id: 7,
              heading: "Total Vehicle Due",
              data: [
                {
                  totalVehicalDue: "text",
                },
              ],
            },
            {
              id: 8,
              heading: "Total Vehicle Due Without Redemption Fee",
              data: [
                {
                  totalVehicleDueWithoutRedemptionFee: "text",
                },
              ],
            },
            {
              id: 9,
              heading: "Number of days personals stored",
              data: [
                {
                  numberOfDaysPersonalsStored: "text",
                },
              ],
            },
            {
              id: 10,
              heading: "Number of days vehicle stored",
              data: [
                {
                  numberOfDaysvehicalStored: "text",
                },
              ],
            },
            {
              id: 11,
              heading: "Redemption Fee",
              data: [
                {
                  redemptionFee: "text",
                },
              ],
            },
            {
              id: 12,
              heading: "Administrative Fee",
              data: [
                {
                  adminstrationFee: "text",
                },
              ],
            },
            {
              id: 13,
              heading: "Transport Company Name",
              data: [
                {
                  transportCompanyName: "text",
                },
              ],
            },
            {
              id: 14,
              heading: "Transport Date",
              data: [
                {
                  transportDate: "date",
                },
              ],
            },
            {
              id: 15,
              heading: "Auction Name",
              data: [
                {
                  auctionName: "text",
                },
              ],
            },
            {
              id: 16,
              heading: "Auction Address",
              data: [
                {
                  auctionAddress: "text",
                },
              ],
            },
            {
              id: 17,
              heading: "Auction City",
              data: [
                {
                  auctionCity: "text",
                },
              ],
            },
            {
              id: 18,
              heading: "Auction State",
              data: [
                {
                  auctionState: "text",
                },
              ],
            },
            {
              id: 19,
              heading: "Auction Zip",
              data: [
                {
                  auctionZip: "text",
                },
              ],
            },
            {
              id: 20,
              heading: "Auction Phone",
              data: [
                {
                  auctionPhone: "text",
                },
              ],
            },
            {
              id: 21,
              heading: "Auction Fax",
              data: [
                {
                  auctionFax: "text",
                },
              ],
            },
            {
              id: 22,
              heading: "Transport Notes",
              data: [
                {
                  transportNotes: "text",
                },
              ],
            },
            {
              id: 23,
              heading: "Delivery Address",
              data: [
                {
                  deliveryAddress: "text",
                },
              ],
            },
            {
              id: 24,
              heading: "Delivery City",
              data: [
                {
                  deliveryCity: "text",
                },
              ],
            },
            {
              id: 25,
              heading: "Delivery State",
              data: [
                {
                  deliveryState: "text",
                },
              ],
            },
            {
              id: 26,
              heading: "Delivery Zip",
              data: [
                {
                  deliveryZip: "text",
                },
              ],
            },
            {
              id: 27,
              heading: "Delivery Fax",
              data: [
                {
                  deliveryFax: "text",
                },
              ],
            },
            {
              id: 28,
              heading: "Delivery Name",
              data: [
                {
                  deliveryName: "text",
                },
              ],
            },
            {
              id: 29,
              heading: "Delivery Phone",
              data: [
                {
                  deliveryPhone: "text",
                },
              ],
            },
          ],
        },
        {
          id: 16,
          heading: "Misc",
          data: [
            {
              id: 1,
              heading: "Today's Date",
              data: [
                {
                  todaysDate: "date",
                },
              ],
            },
            {
              id: 2,
              heading: "Today's Time",
              data: [
                {
                  todaysTime: "time",
                },
              ],
            },
            {
              id: 3,
              heading: "Additional Information on case",
              data: [
                {
                  additionalInformationOnCase: "text",
                },
              ],
            },
            {
              id: 4,
              heading: "Current Username logged in",
              data: [
                {
                  currentUsernameLoggedIn: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Current Username logged in Email Address",
              data: [
                {
                  currentUsernameLoggedInEmailAddress: "text",
                },
              ],
            },
            {
              id: 6,
              heading: "Formatted output of all bids on a case",
              data: [
                {
                  formattedOutputOfAllBidsOnCase: "text",
                },
              ],
            },
            {
              id: 7,
              heading: "Last 6 of the case number (skip extension)",
              data: [
                {
                  lastOfTheCaseNumber: "text",
                },
              ],
            },
            {
              id: 8,
              heading: "Case's claim number",
              data: [
                {
                  caseClaimNumber: "text",
                },
              ],
            },
            {
              id: 9,
              heading: "Investigator",
              data: [
                {
                  investigator: "text",
                },
              ],
            },
            {
              id: 10,
              heading: "Office Notes",
              data: [
                {
                  officeNotes: "text",
                },
              ],
            },
          ],
        },
        {
          id: 17,
          heading: "Updates",
          data: [
            {
              id: 1,
              heading: "All Updates",
              data: [
                {
                  allUpdates: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "Last Update",
              data: [
                {
                  lastUpdate: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "Last Update (including invisible updates)",
              data: [
                {
                  lastUpdateinvisibleUpdates: "text",
                },
              ],
            },
          ],
        },
        {
          id: 18,
          heading: "ICRA",
          data: [
            {
              id: 1,
              heading: "IL Recovery Ticket",
              data: [
                {
                  ilRecoveryTicket: "text",
                },
              ],
            },
            {
              id: 2,
              heading: "First Notice By",
              data: [
                {
                  firstNoticeBy: "text",
                },
              ],
            },
            {
              id: 3,
              heading: "First Notice Date",
              data: [
                {
                  firstNoticeDate: "date",
                },
              ],
            },
            {
              id: 4,
              heading: "Second Notice By",
              data: [
                {
                  secondNoticeBy: "text",
                },
              ],
            },
            {
              id: 5,
              heading: "Second Notice Date",
              data: [
                {
                  secondNoticedate: "date",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const MenuItem = ({ item, isTopLevel = false }) => {
    const [collapsed, setCollapsed] = useState(isTopLevel ? false : true);

    const toggleCollapse = () => {
      setCollapsed(!collapsed);
    };


    return (
      <div>
        <div onClick={toggleCollapse} className="main-menu">
          <div className="hstack justify-content-between">
            <p className="m-0">{item.heading}</p>
            <span>
              {item.data && (
                <i
                  className={`fa-solid ${collapsed ? "fa-angle-down" : "fa-chevron-up"}`}
                ></i>
              )}
            </span>
          </div>
        </div>
        {item.data && !collapsed && (
          <div className="Sub_menu">
            {item.data.map((subItem) => (
              <MenuItem key={subItem.id} item={subItem} />
            ))}
          </div>
        )}
      </div>
    );
  };
  const SideMenu = ({ data }) => {
    return (
      <>
        {data.map((item, index) => (
          <MenuItem key={index} item={item} />
        ))}
      </>
    );
  };

  
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={2} className="p-0">
            <div className="MainLogos1 pt-3">
              <h3>Secured365</h3>
            </div>
            <div className="sidebar___height">
              <div>
                <SideMenu data={AllFormEdits} />
              </div>
            </div>
          </Col>

          <Col md={10} className="p-0">
            <div className="header_main">
              <ClientInfoDetails />
              <BorrowerInfoDetails />
              <CompanyInfoDetails />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default AllFormEditDataDetails;
