import React, { useState, useRef } from "react";
import "../CSS/accordian.css";
import { Accordion, Container, Nav } from "react-bootstrap";
import DueOverDataTable from "./table";
import StatusAndOrderTableData from "./statusAndOrdeTable";
import FormEditModalDetails from "./formEditModal";

const AccordianPageData = ({ accorData }) => {
  const [showAllEdit, setShowAllEdit] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState(null);
  const accordionRef = useRef(null);
  

  const handleEditData = (data, index) => {
    setShowAllEdit(true);
    setFormData(data);
    setActiveTab(index);
  };

  const AccordDetails = (data) => {
    const keysArray = Object.keys(data);
    const dataArray = keysArray.map((key) => data[key]);
    const heading = keysArray.map((caseItem, index) => (
      <th className="border-0" key={index}>
        {caseItem}
      </th>
    ));

    const rowData = dataArray.map((caseItem, index) => (
      <td className="border-0" key={index}>
        {caseItem}
      </td>
    ));
    const allheading = <tr>{heading}</tr>;
    const allrowdata = <tr>{rowData}</tr>;
    return (
      <DueOverDataTable
        tableRows={allrowdata}
        headingData={allheading}
        border={false}
      />
    );
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
    setShowAllEdit(false);
    accordionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Container fluid>
        <div className="nav_tab">
          <Nav variant="underline" defaultActiveKey="link-0">
            {accorData.map((caseItem, index) => (
              <Nav.Item key={index}>
                <Nav.Link
                  eventKey={`link-${index}`}
                  onClick={() => handleTabClick(index)}
                  // onClick={() => { setActiveTab(index); setShowAllEdit(false); }}
                >
                  {caseItem?.heading}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>
      </Container>
      <StatusAndOrderTableData />
      <Container fluid>
        {accorData.map((caseItem, index) => (
          <div className="voluntaryies_repo" key={index} ref={index === activeTab ? accordionRef : null}>
            <Accordion activeKey={activeTab === index ? "0" : null}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>{caseItem?.heading}</Accordion.Header>
                <Accordion.Body>
                  <div className="hstack head_span">
                    {AccordDetails(caseItem?.data)}
                    <span>
                      <i
                        className="fa-regular fa-pen-to-square"
                        onClick={() => handleEditData(caseItem, index)}
                      ></i>
                    </span>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ))}
      </Container>
      {showAllEdit && (
        <FormEditModalDetails status={showAllEdit} formData={formData} />
      )}
    </>
  );
};

export default AccordianPageData;
