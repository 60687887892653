import React from "react";
import "../CSS/morepage.css";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
import StatusAndOrderTableData from "../Component/statusAndOrdeTable";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
// import FormEditModalDetails from "../Component/formEditModal";
const FormPageDetails = () => {
  // const [showFormData, setShowFormData] = useState(false);

  // const handleAlertData = () => {
  //   setShowFormData(!showFormData);
  // };
  const navigate = useNavigate();

function handleAlertData() {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You are about to edit the agent release.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'OK',
    cancelButtonText: 'Cancel'
  }).then((result) => {
    if (result.isConfirmed) {
      // Handle OK button click
      // Add your logic here for what should happen when the user clicks OK
      navigate('/edit-form');
    }
  });
}
  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <StatusAndOrderTableData />
              <Tabs
                defaultActiveKey="Release Notes"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="Release Notes" title="Release Notes">
                  <div className="case_complaint">
                    <p>Property Release Note</p>
                    <i
                      className="fa-regular fa-pen-to-square"
                      // onClick={() => handleAlertData()}
                    ></i>
                  </div>
                  <div className="no_complain">
                    <p>This property belongs to Symen</p>
                  </div>
                </Tab>
                <Tab eventKey="Form Notes" title="Form Notes">
                  <div className="case_complaint">
                    <p>Case Form Notes</p>
                    <i className="fa-regular fa-pen-to-square"></i>
                  </div>
                  <div className="no_complain">
                    <p>Registred on 24-08-2023</p>
                  </div>
                </Tab>
                <Tab eventKey="RDN Form" title="RDN Form">
                  <div className="case_complaint">
                    <p className="m-0">RDN Standard Form</p>
                    <i className="fa-regular fa-pen-to-square"></i>
                  </div>
                  <div className="no_complain">
                    <Row>
                      <Col md={4}>
                        <ul>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Agent Release</p>
                              <p>
                                <i
                                  className="fa-regular fa-pen-to-square"
                                  onClick={handleAlertData}
                                ></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Blank C/R Form</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Blanket Hold Harmless Agreement</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Closed Report</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>

                          <li>
                            <div className="hstack justify-content-between">
                              <p>File Cover Sheet</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Hold Report</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Off-Hold Report</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Personal Property Notice</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Police Notification Letter</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Printable Updates Page</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Repo Notification Letter</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Skip / Trap Letter</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Vehicle Receipt (no fees)</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Voluntary Surrender Letter</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                        </ul>
                      </Col>
                      <Col md={4}>
                        <ul>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Agent Transport Form</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Blank First Hit Update</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Case Form Letter</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Closed Report 2 (no client info)</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Forwarded Repo Order</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Inspection Affidavit</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Office Notes</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Personal Property Receipt</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Printable ALL Updates</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Release Request</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Repo Order</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Transport Request</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Vehicle Release to Debtor</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                        </ul>
                      </Col>
                      <Col md={4}>
                        <ul>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Bids Report</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Blank Personals Form</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Client Acknowledgement Letter</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Delivery Ticket</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Hold Harmless and Blank Order Form</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Notify Client of Transport</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>On Hold Report 2 (no client info)</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Picture Report</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Printable Last Update</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Release Request [2]</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Repo Order (Forwarding)</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Vehicle Receipt</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="hstack justify-content-between">
                              <p>Vehicle Release to Other</p>
                              <p>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </p>
                            </div>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                    {/* <div className="view_all_from">
                        <button>View All Form</button>  <div className="view_all_from">
                        <button>View All Form</button>
                    </div>
                    </div> */}
                  </div>
                </Tab>
                <Tab eventKey="Office Form" title="Office Form">
                  <div className="case_complaint">
                    <p>Custom Office Form</p>
                    <i className="fa-regular fa-pen-to-square"></i>
                  </div>
                  <div className="no_complain">
                    <p>No Office form</p>
                  </div>
                </Tab>
                <Tab eventKey="Specific Form" title="Specific Form">
                  <div className="case_complaint">
                    <p>Client Specific Form</p>
                    <i className="fa-regular fa-pen-to-square"></i>
                  </div>
                  <div className="no_complain">
                    <p>There were no specific form</p>
                  </div>
                </Tab>
                <Tab eventKey="Forms Help" title="Forms Help">
                  <div className="case_complaint">
                    <p>Forms Help</p>
                    <i className="fa-regular fa-pen-to-square"></i>
                  </div>
                  <div className="no_complain">
                    <p>Template Replacement Key</p>
                  </div>
                </Tab>
                <Tab eventKey="Report Template" title="Report Template">
                  <div className="case_complaint">
                    <p>Condition Report Template</p>
                    <i className="fa-regular fa-pen-to-square"></i>
                  </div>
                  <div className="no_complain">
                    <div>
                      <Row>
                        <Col md={4}>
                          <p>Car Condition Report</p>
                        </Col>
                        <Col md={4}>
                          <p>Motorcycle Condition Report</p>
                        </Col>
                        <Col md={4}>
                          <p>Onyx Condition Report</p>
                        </Col>
                        <Col md={4}>
                          <p>R/V Condition Report</p>
                        </Col>
                        <Col md={4}>
                          <p>Renaissance Condition Report</p>
                        </Col>
                        <Col md={4}>
                          <p>Regional Acceptance Condition Report</p>
                        </Col>
                        <Col md={4}>
                          <p>Truck Condition Report</p>
                        </Col>
                        <Col md={4}>
                          <p>Watercraft Condition Report</p>
                        </Col>
                        <Col md={4}></Col>
                        <Col md={4}>
                          <p>CitiFinancial Auto Condition Report</p>
                        </Col>
                        <Col md={4}>
                          <p>Nuvell Condition Report</p>
                        </Col>
                        <Col md={4}></Col>
                      </Row>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Report Status" title="Report Status">
                  <div className="case_complaint">
                    <p>Condition Report Status</p>
                    <i className="fa-regular fa-pen-to-square"></i>
                  </div>
                  <div className="no_complain">
                    <div className="standard_trucks">
                      <div>
                        <p>Standard Truck</p>
                      </div>
                      <div className="icon_sets">
                        <i className="fa-regular fa-pen-to-square"></i>
                        <i className="fa-solid fa-print"></i>
                        <i className="fa-regular fa-envelope"></i>
                        <i className="fa-solid fa-trash"></i>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
            {/* {showFormData && <FormEditModalDetails status={showFormData} formData={formData} />} */}
          </Container>
        </div>
      </div>
    </>
  );
};
export default FormPageDetails;
