import React, { useState } from "react";
import "../CSS/morepage.css";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
import StatusAndOrderTableData from "../Component/statusAndOrdeTable";
import { Col, Container, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
const UpdatePageDetails = () => {
  const [showUpdateEdit, setShowUpdateEdit] = useState(false);
  const [showEditAddress, setShowEditAddress] = useState(false);
  const [showEditNewUpdate, setShowEditNewUpdate] = useState(false);

  const handleCloseUpdateEdit = () => setShowUpdateEdit(false);
  const handleShowUpdateEdit = () => setShowUpdateEdit(true);

  const handleCloseAddress = () => setShowEditAddress(false);
  const handleShowAddress = () => setShowEditAddress(true);

  const handleCloseNewUpdate = () => setShowEditNewUpdate(false);
  const handleShowNewUpdate = () => setShowEditNewUpdate(true);

  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <StatusAndOrderTableData />
              <Tabs
                defaultActiveKey="Address"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="Address" title="Address">
                  <div className="case_complaint">
                    <p>Address 1</p>
                    <p>City</p>
                    <p>Country</p>
                    <p>State</p>
                    <p>Zip</p>
                    <p>Cell</p>
                    <p>Phone</p>
                    <p>Authentication</p>
                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={handleShowAddress}
                    ></i>
                  </div>
                  <div className="no_complain">
                    <div className="add_type1">
                      <p>8665 E. Hartford Dr</p>
                      <p>Scottsdale</p>
                      <p>Maricopa</p>
                      <p>AZ</p>
                      <p>85255</p>
                      <p>602-952-2410</p>
                      <p>602-952-2410</p>
                      <p>Valid</p>
                      <i className="fa-solid fa-trash delete_trash"></i>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Add New Address" title="Add New Address">
                  <div className="case_complaint">
                    <p>Add New Address</p>
                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={handleShowUpdateEdit}
                    ></i>
                  </div>
                  <div className="mb-5">
                    <div className="no_complain">
                      <div>
                        <Row>
                          <Col className="mb-3">
                            <h3>Name</h3>
                            <Form.Control required type="text" />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={8} className="mb-3">
                            <h3>Address</h3>
                            <Form.Control
                              as="textarea"
                              style={{ height: "50px" }}
                            />
                          </Col>
                          <Col md={4} className="mb-3">
                            <h3>Unit</h3>
                            <Form.Control
                              as="textarea"
                              style={{ height: "50px" }}
                            />
                          </Col>
                          <Col md={4} className="mb-3">
                            <h3>City</h3>
                            <Form.Control required type="text" />
                          </Col>
                          <Col md={4} className="mb-3">
                            <h3>State</h3>
                            <Form.Select aria-label="Default select example">
                              <option>Default</option>
                              <option value="Low">Low</option>
                              <option value="Medium">Medium</option>
                              <option value="High">High</option>
                            </Form.Select>
                          </Col>
                          <Col md={4} className="mb-3">
                            <h3>Zip</h3>
                            <Form.Control required type="text" />
                          </Col>
                          <Col md={6} className="mb-3">
                            <h3>County</h3>
                            <Form.Control required type="text" />
                          </Col>
                          <Col md={6} className="mb-3">
                            <h3>Map Coordinates</h3>
                            <Form.Control required type="text" />
                          </Col>
                          <Col md={6} className="mb-3">
                            <h3>Category</h3>
                            <Form.Select aria-label="Select Category">
                              <option>Select Category</option>
                              <option value="3rd Party Address">
                                3rd Party Address
                              </option>
                              <option value="Additional Address">
                                Additional Address
                              </option>
                              <option value="Borrower - Home">
                                Borrower - Home
                              </option>
                              <option value="Borrower - Previous ">
                                Borrower - Previous{" "}
                              </option>
                              <option value="Borrower - Work">
                                Borrower - Work
                              </option>
                              <option value="Co Signer - Home">
                                Co Signer - Home
                              </option>
                              <option value="Co Signer - Previous ">
                                Co Signer - Previous{" "}
                              </option>
                              <option value="Co Signer - Work ">
                                Co Signer - Work{" "}
                              </option>
                              <option value="DMV Address">DMV Address</option>
                              <option value="Landlord Address">
                                Landlord Address
                              </option>
                              <option value="LPR Location">LPR Location</option>
                              <option value="Pickup Address">
                                Pickup Address
                              </option>
                              <option value="References">References</option>
                            </Form.Select>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h3>Dwelling Type</h3>
                            <Form.Select aria-label="Select Dwelling Type">
                              <option> Select Dwelling Type..</option>
                              <option value="Unknown">Unknown</option>
                              <option value="Single Family ">
                                Single Family{" "}
                              </option>
                              <option value="Commercial Mail Receiving Agency">
                                Commercial Mail Receiving Agency
                              </option>
                              <option value="Multi - Unit Complex">
                                Multi - Unit Complex
                              </option>
                            </Form.Select>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h3>Phone</h3>
                            <Form.Control required type="text" />
                          </Col>
                          <Col md={6} className="mb-3">
                            <h3>Cell</h3>
                            <Form.Control required type="text" />
                          </Col>
                          <Col md={12} className="mb-3">
                            <h3>Authentication</h3>
                            <Form.Select aria-label="Default select example">
                              <option>Default</option>
                              <option value="Low">Low</option>
                              <option value="Medium">Medium</option>
                              <option value="High">High</option>
                            </Form.Select>
                          </Col>
                          <Col md={6}>
                            <div className="create_button">
                              <button>Create</button>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="create_button">
                              <button>Create(Invisible)</button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="New Case Update" title="New Case Update">
                  <div className="case_complaint">
                    <p>New Case Update</p>
                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={handleShowNewUpdate}
                    ></i>
                  </div>
                  <div className="no_complain">
                    <Row>
                      <Col md={3}>
                        <div className="mb-3">
                          <h3>Date & Time</h3>
                          <Form.Control required type="date" />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <h3>Type</h3>
                          <Form.Select aria-label="Default select example">
                            <option>Agent Request info</option>
                            <option value="(O)Agent Requesting Info">
                              (O)Agent Requesting Info
                            </option>
                            <option value="(O)Client-Requesting Update">
                              (O)Client-Requesting Update
                            </option>
                            <option value="(O)Client-New Info">
                              (O)Client-New Info
                            </option>
                            <option value="(O)Client-Account On Hold">
                              (O)Client-Account On Hold
                            </option>
                            <option value="(O)Client-Account Close">
                              (O)Client-Account Close
                            </option>
                            <option value="(O)Address Confirmed, Collateral Inaccessible">
                              (O)Address Confirmed, Collateral Inaccessible
                            </option>
                            <option value="(O)Address Confirmed, Debtor Unwilling to Surrender">
                              (O)Address Confirmed, Debtor Unwilling to
                              Surrender
                            </option>
                            <option value="(O)Address Confirmed, Collateral Not Present">
                              (O)Address Confirmed, Collateral Not Present
                            </option>
                            <option value="(O)Address Occupied - Will Continue Recovery Efforts ">
                              (O)Address Occupied - Will Continue Recovery
                              Efforts{" "}
                            </option>
                            <option value="(O)Ran POE - No Unit Spotted ">
                              (O)Ran POE - No Unit Spotted{" "}
                            </option>
                            <option value="(O)Ran LPR Hit - No Unit Spotted ">
                              (O)Ran LPR Hit - No Unit Spotted{" "}
                            </option>
                            <option value="(O)Ran LPR Hit - Collateral Inaccessible">
                              (O)Ran LPR Hit - Collateral Inaccessible
                            </option>
                            <option value="(O)Address Information Needed From Client">
                              (O)Address Information Needed From Client
                            </option>
                            <option value="(O)Agent - Update">
                              (O)Agent - Update
                            </option>
                          </Form.Select>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <h3>Priority</h3>
                          <Form.Select aria-label="Default select example">
                            <option>Default</option>
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                          </Form.Select>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <h3>Company</h3>
                          <Form.Select aria-label="Default select example">
                            <option>Default</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <h3>Update Address</h3>
                          <Form.Select aria-label="Default select example">
                            <option>Default</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                      </Col>
                      <Col md={9}>
                        <div className="mb-3">
                          <h3>Details</h3>
                          <Form.Control
                            as="textarea"
                            style={{ height: "50px" }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="create_button">
                          <button>Create</button>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="create_button">
                          <button>Create(Invisible)</button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Tab>
                <Tab eventKey="Updates" title="Updates">
                  <div className="case_complaint">
                    <p>Updates</p>
                    <p>
                      <Form.Select aria-label="Default select example">
                        <option>Default</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </p>
                    <p>All Visible to client</p>
                    <p>All Visible to Collector</p>
                    <p>
                      <i className="fa-regular fa-pen-to-square"></i>
                    </p>
                  </div>
                  <div className="no_complain">
                    <div className="valid_point mb-3">
                      <Row>
                        <Col md={3}>
                          <div>
                            <h4>Saved To RDN</h4>
                            <p>08/23/2017 01:39 PM</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <h4>Last Updated</h4>
                            <p>By MARLENA JARRELL (User)</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <h4>Update Data/Time</h4>
                            <p>08/23/2017 01:39 PM</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <h4>Company</h4>
                            <p>Midnight Express Auto Recovery Inc.</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <h4>Update Type</h4>
                            <p>(O) Other</p>
                          </div>
                        </Col>
                        <Col md={9}>
                          <h4>Details</h4>
                          <p>
                            REMINDER TO STACEY ABOUT PAYMENT,Not Visible Edit
                            Delete Collector Print
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <div className="valid_point mb-3">
                      <Row>
                        <Col md={3}>
                          <div>
                            <h4>Saved To RDN</h4>
                            <p>08/23/2017 01:39 PM</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <h4>Last Updated</h4>
                            <p>By MARLENA JARRELL (User)</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <h4>Update Data/Time</h4>
                            <p>08/23/2017 01:39 PM</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <h4>Company</h4>
                            <p>Midnight Express Auto Recovery Inc.</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <h4>Update Type</h4>
                            <p>(O) Other</p>
                          </div>
                        </Col>
                        <Col md={9}>
                          <h4>Details</h4>
                          <p>
                            REMINDER TO STACEY ABOUT PAYMENT,Not Visible Edit
                            Delete Collector Print
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <div className="valid_point mb-3">
                      <Row>
                        <Col md={3}>
                          <div>
                            <h4>Saved To RDN</h4>
                            <p>08/23/2017 01:39 PM</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <h4>Last Updated</h4>
                            <p>By MARLENA JARRELL (User)</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <h4>Update Data/Time</h4>
                            <p>08/23/2017 01:39 PM</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <h4>Company</h4>
                            <p>Midnight Express Auto Recovery Inc.</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <h4>Update Type</h4>
                            <p>(O) Other</p>
                          </div>
                        </Col>
                        <Col md={9}>
                          <h4>Details</h4>
                          <p>
                            REMINDER TO STACEY ABOUT PAYMENT,Not Visible Edit
                            Delete Collector Print
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
            <div className="no_complain"></div>
          </Container>
        </div>
      </div>

      <Modal
        show={showUpdateEdit}
        onHide={handleCloseUpdateEdit}
        className="modal_type custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="note_update">
              <b>Edit New Address</b>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row className="mb-3">
              <Col md={4} className="mb-3">
                <h3 className="note_update">Date & Time</h3>
                <div className="date_time_set">
                  <Form.Control required type="date" />
                  <Form.Control required type="time" />
                </div>
              </Col>
              <Col md={8} className="mb-3">
                <h3 className="note_update">Address</h3>
                <Form.Select aria-label="Default select example">
                  <option>Title Received</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Col>
              <Col md={3} className="mb-3">
                <div>
                  <h3 className="note_update">City</h3>
                  <Form.Control required type="text" />
                </div>
              </Col>
              <Col md={3} className="mb-3">
                <div>
                  <h3 className="note_update">State</h3>
                  <Form.Control required type="text" />
                </div>
              </Col>
              <Col md={3} className="mb-3">
                <div>
                  <h3 className="note_update">Country</h3>
                  <Form.Control required type="text" />
                </div>
              </Col>
              <Col md={3} className="mb-3">
                <h3 className="note_update">Zip</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={3} className="mb-3">
                <h3 className="note_update">Cell</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={3} className="mb-3">
                <h3 className="note_update">Phone</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Authentication</h3>
                <Form.Select aria-label="Default select example">
                  <option>Default</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Col>
              {/* <Col md={12} c className="mb-3">
                <h3 className="note_update">Notes</h3>
                <Form.Control as="textarea" style={{ height: "100px" }} />
              </Col> */}
              <Col md={6} className="note_update">
                <div className="create_button">
                  <button>Create</button>
                </div>
              </Col>
              <Col md={6} className="note_update">
                <div className="create_button">
                  <button>Cancel</button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showEditAddress}
        onHide={handleCloseAddress}
        className="modal_type custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="note_update">
              <b>Edit Address</b>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="edit_notes_office">
            <Row className="mb-3">
              <Col md={4} className="mb-3">
                <h3 className="note_update">Address 1</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">City</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Country</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">State</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Zip</h3>
                <Form.Control required type="number" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Cell</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Phone</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Authentication</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Create</button>
                </div>
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Cancel</button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showEditNewUpdate}
        onHide={handleCloseNewUpdate}
        className="modal_type custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="note_update">
              <b>Edit New Case Update</b>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="edit_notes_office">
            <Row className="mb-3">
              <Col md={4} className="mb-3">
                <h3 className="note_update">Date & Time</h3>
                <div className="date_time_set">
                  <Form.Control required type="date" />
                  <Form.Control required type="time" />
                </div>
              </Col>
              <Col md={8} className="mb-3">
                <h3 className="note_update">Type</h3>
                <Form.Select aria-label="Default select example">
                  <option>Agent Request info</option>
                  <option value="(O)Agent Requesting Info">
                    (O)Agent Requesting Info
                  </option>
                  <option value="(O)Client-Requesting Update">
                    (O)Client-Requesting Update
                  </option>
                  <option value="(O)Client-New Info">(O)Client-New Info</option>
                  <option value="(O)Client-Account On Hold">
                    (O)Client-Account On Hold
                  </option>
                  <option value="(O)Client-Account Close">
                    (O)Client-Account Close
                  </option>
                  <option value="(O)Address Confirmed, Collateral Inaccessible">
                    (O)Address Confirmed, Collateral Inaccessible
                  </option>
                  <option value="(O)Address Confirmed, Debtor Unwilling to Surrender">
                    (O)Address Confirmed, Debtor Unwilling to Surrender
                  </option>
                  <option value="(O)Address Confirmed, Collateral Not Present">
                    (O)Address Confirmed, Collateral Not Present
                  </option>
                  <option value="(O)Address Occupied - Will Continue Recovery Efforts ">
                    (O)Address Occupied - Will Continue Recovery Efforts{" "}
                  </option>
                  <option value="(O)Ran POE - No Unit Spotted ">
                    (O)Ran POE - No Unit Spotted{" "}
                  </option>
                  <option value="(O)Ran LPR Hit - No Unit Spotted ">
                    (O)Ran LPR Hit - No Unit Spotted{" "}
                  </option>
                  <option value="(O)Ran LPR Hit - Collateral Inaccessible">
                    (O)Ran LPR Hit - Collateral Inaccessible
                  </option>
                  <option value="(O)Address Information Needed From Client">
                    (O)Address Information Needed From Client
                  </option>
                  <option value="(O)Agent - Update">(O)Agent - Update</option>
                </Form.Select>
              </Col>
          
              <Col md={6} className="mb-3">
                <h3 className="note_update">Set Priority</h3>
                <Form.Select aria-label="Default select example">
                  <option>Default</option>
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </Form.Select>
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Company</h3>
                <Form.Select aria-label="Default select example">
                  <option>Default</option>
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </Form.Select>
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Update Address</h3>
                <Form.Select aria-label="Default select example">
                  <option>Default</option>
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </Form.Select>
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Details</h3>
                <Form.Control as="textarea" style={{ height: "50px" }} />
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Create</button>
                </div>
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Cancel</button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default UpdatePageDetails;
