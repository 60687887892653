import React from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import "../CSS/morepage.css";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
import SecuredCaseDetailsData from "../Component/securedCaseDetails";
import SecuredPhotosDocsData from "../Component/securedPhotosDocs";
import InvoiceCaseData from "../Component/invoicedetails";
import { useNavigate } from "react-router-dom";

const SecuredCasePageDetails = () => {
  const navigate = useNavigate();

  const handleTabSelect = (eventKey) => {
    if (eventKey === "Form") {
      navigate('/form');
    }
  };

  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section ">
              <Tabs
                defaultActiveKey="Case Details"
                id="uncontrolled-tab-example"
                className="mb-1"
                onSelect={handleTabSelect} // Call handleTabSelect on tab selection
              >
                <Tab eventKey="Case Details" title="Case Details">
                  <SecuredCaseDetailsData />
                </Tab>
                <Tab eventKey="Photos & Docs" title="Photos & Docs">
                  <SecuredPhotosDocsData />
                </Tab>
                <Tab eventKey="Invoice" title="Invoice">
                  <InvoiceCaseData />
                </Tab>
                <Tab eventKey="Form" title="Form">
                  {/* This tab navigates to the "/form" route when clicked */}
                </Tab>
              </Tabs>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default SecuredCasePageDetails;
