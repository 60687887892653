import React, { useEffect, useState } from "react";
import "../CSS/header.css";
import Dhonipic from "../img/dhoni.webp";
import { useLocation } from "react-router-dom";
const HeaderPage = () => {
  const [userData, setUserData] = useState("");

  const location = useLocation();
  const pageName = location.pathname;
  const formattedPathItem = pageName.replace(/^\//, '').replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
  const handleGoBack = () => {
    window.history.back();
  };
useEffect(()=>{
  const data = localStorage.getItem('userData');
  if(data){
    setUserData(JSON.parse(data));  }
},[])
  return (
    <>
      <div className="header">
        <div className="left" onClick={handleGoBack}>
          <i className="fa-solid fa-caret-left"></i>
          <h5>{ formattedPathItem }</h5>            
        </div>
        <div className="right">
          <img className="profile-pic" src={Dhonipic} alt="" />
          <div className="name_side">
            <p className="m-0">Welcome Back!</p>
            <h6>{userData?.firstName+ " " + userData?.lastName}</h6>
          </div> 
        </div>
      </div>
    </>
  );
};
export default HeaderPage;
