import React, { useState } from "react";
import "../CSS/morepage.css";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
import StatusAndOrderTableData from "../Component/statusAndOrdeTable";
import { Col, Container, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
const RemainderPageDetails = () => {
  // const [showEditReminder, setShowEditReminder] = useState(false);
  const [showEditPriority, setShowEditPriority] = useState(false);
  // const [showEditAlert, setShowEditAlert] = useState(false);

  // const handleCloseReminder = () => setShowEditReminder(false);
  // const handleShowReminder = () => setShowEditReminder(true);

  const handleClosePriority = () => setShowEditPriority(false);
  const handleShowPriority = () => setShowEditPriority(true);

  // const handleCloseAlert = () => setShowEditAlert(false);
  // const handleShowAlert = () => setShowEditAlert(true);
  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <StatusAndOrderTableData />
              <div className="remainderCaseDetails">
                <Tabs
                  defaultActiveKey="No Priority"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="No Priority" title="No Priority">
                    <div className="case_complaint">
                      <p>Date & Time</p>
                      <p>For</p>
                      <p>Set By</p>
                      <p>Details</p>
                      <i
                        className="fa-regular fa-pen-to-square"
                        onClick={handleShowPriority}
                      ></i>
                    </div>
                    <div className="no_complain">
                      <div className="stacy_call">
                        <p>2024-03-20 12:20PM</p>
                        <p>Stacy Well(user)</p>
                        <p>Mark Waugh</p>
                        <p>CLIENT WOULD LIKE A CALL AS SOON AS .</p>
                        <p></p>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Low Priority" title="Low Priority">
                    <div className="case_complaint">
                      <p>Date & Time</p>
                      <p>For</p>
                      <p>Set By</p>
                      <p>Details</p>
                      <i
                        className="fa-regular fa-pen-to-square"
                        onClick={handleShowPriority}
                      ></i>
                    </div>
                    <div className="no_complain">
                      <div className="stacy_call">
                        <p>2024-03-20 12:20PM</p>
                        <p>Stacy Well(user)</p>
                        <p>Mark Waugh</p>
                        <p>CLIENT WOULD LIKE A CALL AS SOON .</p>
                        <p></p>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Medium Priority" title="Medium Priority">
                    <div className="case_complaint">
                      <p>Date & Time</p>
                      <p>For</p>
                      <p>Set By</p>
                      <p>Details</p>
                      <i
                        className="fa-regular fa-pen-to-square"
                        onClick={handleShowPriority}
                      ></i>
                    </div>
                    <div className="no_complain">
                      <div className="stacy_call">
                        <p>2024-03-20 12:20PM</p>
                        <p>Stacy Well(user)</p>
                        <p>Mark Waugh</p>
                        <p>CLIENT WOULD LIKE A CALL AS SOON .</p>
                        <p></p>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="High Priority" title="High Priority">
                    <div className="case_complaint">
                      <p>Date & Time</p>
                      <p>For</p>
                      <p>Set By</p>
                      <p>Details</p>
                      <i
                        className="fa-regular fa-pen-to-square"
                        onClick={handleShowPriority}
                      ></i>
                    </div>
                    <div className="no_complain">
                      <div className="stacy_call">
                        <p>2024-03-20 12:20PM</p>
                        <p>Stacy Well(user)</p>
                        <p>Mark Waugh</p>
                        <p>CLIENT WOULD LIKE A CALL AS SOON AS .</p>
                        <p></p>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="New Reminder" title=" New Reminders">
                    <div className="case_complaint">
                      <p>Reminders</p>
                      <i
                        className="fa-regular fa-pen-to-square"
                        // onClick={handleShowReminder}
                      ></i>
                    </div>
                    <div className="no_complain">
                      <Row>
                        <Col md={4} className="mb-3">
                          <h3>Date & Time</h3>
                          <div className="date_time_set">
                            <Form.Control required type="date" />
                            <Form.Control required type="time" />
                          </div>
                        </Col>
                        <Col md={4} className="mb-3">
                          <h3>For</h3>
                          <Form.Select aria-label="Default select example">
                            <option>Default</option>
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                          </Form.Select>
                        </Col>
                        <Col md={4} className="mb-3">
                          <h3>Set By</h3>
                          <Form.Control required type="text" />
                        </Col>
                        <Col md={4} className="mb-3">
                          <h3>Set Priority</h3>
                          <Form.Select aria-label="Default select example">
                            <option>Default</option>
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                          </Form.Select>
                        </Col>
                        <Col md={8} className="mb-3">
                          <h3>Details</h3>
                          <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                          />
                        </Col>
                        <Col md={6}>
                          <div className="create_button">
                            <button>Create</button>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="create_button">
                            <button>Cancel</button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab>
                  <Tab eventKey="Alert" title="Alert">
                    <div className="case_complaint align-items-center">
                      <p className="m-0">Alert</p>
                      <i className="fa-regular fa-pen-to-square"
                      //  onClick={handleShowAlert}
                       ></i>
                    </div>
                    <div className="no_complain">
                      <Row>
                        <Col md={4} className="mb-3">
                          <h3>Date & Time</h3>
                          <div className="date_time_set">
                            <Form.Control required type="date" />
                            <Form.Control required type="time" />
                          </div>
                        </Col>
                        <Col md={4} className="mb-3">
                          <h3>For</h3>
                          <Form.Select aria-label="Default select example">
                            <option>Default</option>
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                          </Form.Select>
                        </Col>
                        <Col md={4} className="mb-3">
                          <h3>Set By</h3>
                          <Form.Control required type="text" />
                        </Col>

                        <Col md={12} className="mb-3">
                          <h3>Alert Message</h3>
                          <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                          />
                        </Col>
                        <Col md={6}>
                          <div className="create_button">
                            <button>Create</button>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="create_button">
                            <button>Cancel</button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Container>
        </div>
      </div>
      {/* <Modal
        show={showEditReminder}
        onHide={handleCloseReminder}
        className="modal_type custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="note_update">
              <b>Edit Reminders</b>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="edit_notes_office">
            <Row className="mb-3">
              <Col md={4} className="mb-3">
                <h3 className="note_update">Date & Time</h3>
                <div className="date_time_set">
                  <Form.Control required type="date" />
                  <Form.Control required type="time" />
                </div>
              </Col>
              <Col md={8} className="mb-3">
                <h3 className="note_update">By</h3>
                <Form.Select aria-label="Default select example">
                  <option>Title Received</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Set Priority</h3>
                <Form.Select aria-label="Default select example">
                  <option>Default</option>
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </Form.Select>
              </Col>
              <Col md={8} className="mb-3">
                <h3 className="note_update">Notes</h3>
                <Form.Control as="textarea" style={{ height: "100px" }} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="create_button">
                  <button>Create</button>
                </div>
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Cancel</button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal> */}
      <Modal
        show={showEditPriority}
        onHide={handleClosePriority}
        className="modal_type custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="note_update">
              <b>Edit Priority</b>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="edit_notes_office">
            <Row className="mb-3">
              <Col md={6} className="mb-3">
                <h3 className="note_update">Date & Time</h3>
                <div className="date_time_set">
                  <Form.Control required type="date" />
                  <Form.Control required type="time" />
                </div>
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">For</h3>
                <Form.Control required type="text" />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Set By</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Notes</h3>
                <Form.Control as="textarea" style={{ height: "100px" }} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="create_button">
                  <button>Create</button>
                </div>
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Cancel</button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Modal
        show={showEditAlert}
        onHide={handleCloseAlert}
        className="modal_type custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="note_update">
              <b>Edit Alert</b>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="edit_notes_office">
            <Row className="mb-3">
              <Col md={6} className="mb-3">
                <h3 className="note_update">Date & Time</h3>
                <div className="date_time_set">
                  <Form.Control required type="date" />
                  <Form.Control required type="time" />
                </div>
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">For</h3>
                <Form.Control required type="text" />
              </Col>
            </Row>
            <Row>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Set By</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={8} className="mb-3">
                <h3 className="note_update">Alert Message</h3>
                <Form.Control as="textarea" style={{ height: "100px" }} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="create_button">
                  <button>Create</button>
                </div>
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Cancel</button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};
export default RemainderPageDetails;
