import React from "react";
import "../CSS/morepage.css";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
import StatusAndOrderTableData from "../Component/statusAndOrdeTable";
import { Container, Tab, Table, Tabs } from "react-bootstrap";
const UserHistoryPageDetails = () => {
  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <StatusAndOrderTableData />
              <Tabs
                defaultActiveKey="User History"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="User History" title="User History">
                  <div className="table__autoHeight">
                    <Table>
                      <thead className="tableHead__Sticky">
                        <tr>
                          <th>Date & Time</th>
                          <th>Action</th>
                          <th>User</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  {/* <div className="no_complain">
                  </div> */}
                </Tab>
                <Tab eventKey="Agent History" title="Agent History">
                  <div className="table__autoHeight">
                    <Table>
                      <thead className="tableHead__Sticky">
                        <tr>
                          <th>Date & Time</th>
                          <th>Action</th>
                          <th>Agent</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Tab>
                <Tab eventKey="Client History" title="Client History">
                  <div className="table__autoHeight">
                    <Table>
                      <thead className="tableHead__Sticky">
                        <tr>
                          <th>Date & Time</th>
                          <th>Action</th>
                          <th>Client</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                        <tr>
                          <td>08/22/2017 08:19 AM</td>
                          <td>USER Added New Order By</td>
                          <td>Kathy Alexander</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  {/* <div className="no_complain">
                  </div> */}
                </Tab>
              </Tabs>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
export default UserHistoryPageDetails;
