import React from "react";
import "../CSS/accordian.css";
import { Container, Row , Form, Badge, Button,Col, Table} from "react-bootstrap";
// import { Form } from "react-router-dom";
const StatusAndOrderTableData = ()=>{
    return(
        <>
          <Container fluid>
        <div className="voluntary_repo">
          <Row>
            <Form.Group as={Col} md="2">
              <Form.Label>
                <h4>Order To</h4>
              </Form.Label>
              <div className="badge__design">
                <Badge className="badges_box">Involuntary Repo</Badge>
              </div>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>
                <h4>Status</h4>
              </Form.Label>
              <Form.Select aria-label="Default select example w-100">
                <option>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>
                <h4>Add Sub Status</h4>
              </Form.Label>
              <Form.Select aria-label="Default select example w-100">
                <option>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>
                <h4>Search Bar</h4>
              </Form.Label>
              <div className="hstack" style={{position:"relative"}}>
                <Form.Control required type="search" placeholder="Search" />
                <Button className="search_boxes">Search</Button>
              </div>
            </Form.Group>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <div className="voluntary_repo">
          <Table borderless>
            <thead>
              <tr>
                <th>Order Date</th>
                <th>Case#</th>
                <th>Ref. Number</th>
                <th>Client Name</th>
                <th>Collector </th>
                <th>Lien Holder</th>
                <th>Client Acc. Number</th>
                <th>File Folder Labels</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>24-01-20</td>
                <td>2051294338</td>
                <td>79549</td>
                <td>Skip Masters</td>
                <td>Alissa Didion</td>
                <td>Credit Acceptance</td>
                <td>2051294338</td>
                <td>@mdo</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>
        </>
    )
}
export default StatusAndOrderTableData;