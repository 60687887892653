import React from "react";
import "../CSS/morepage.css";
import { Col, Form, Row } from "react-bootstrap";
const BorrowerInfoDetails = () => {
  return (
    <>
      <div className="p-2">
        <div className="mb-3">
          <div className="case_complaint">
            <p>Borrower Info</p>
            <i class="fa-solid fa-minus minus_sign"></i>
          </div>
          <div className="no_complain">
            <div className="all_client_data">
              <Row>
                <Col md={12} className="mb-3">
                  <div>
                    <h3>
                      Addressing To<span>*</span>
                    </h3>
                    <Form.Control required type="text" />
                  </div>
                </Col>
                <Col md={4} className="mb-3">
                  <div>
                    <h3>
                      Borrower First Name<span>*</span>
                    </h3>
                    <Form.Control required type="text" />
                  </div>
                </Col>
                <Col md={4} className="mb-3">
                  <div>
                    <h3>
                      Borrower Middle Name<span>*</span>
                    </h3>
                    <Form.Control required type="text" />
                  </div>
                </Col>
                <Col md={4} className="mb-3">
                  <div>
                    <h3>
                      Borrower Last Name<span>*</span>
                    </h3>
                    <Form.Control required type="text" />
                  </div>
                </Col>
                <Col md={8} className="mb-3">
                  <div>
                    <h3>
                      Address<span>*</span>
                    </h3>
                    <Form.Control required type="text" />
                  </div>
                </Col>
                <Col md={4} className="mb-3">
                  <div>
                    <h3>
                      Client State<span>*</span>
                    </h3>
                    <Form.Control required type="text" />
                  </div>
                </Col>
                <Col md={4} className="mb-3">
                  <div>
                    <h3>
                      Client City<span>*</span>
                    </h3>
                    <Form.Control required type="text" />
                  </div>
                </Col>
                <Col md={4} className="mb-3">
                  <div>
                    <h3>
                      Zip<span>*</span>
                    </h3>
                    <Form.Control required type="text" />
                  </div>
                </Col>
                <Col md={4} className="mb-3">
                  <div>
                    <h3>
                      Country<span>*</span>
                    </h3>
                    <Form.Control required type="text" />
                  </div>
                </Col>
                <Col md={4} className="mb-3">
                  <div>
                    <h3>
                      Phone<span>*</span>
                    </h3>
                    <Form.Control required type="text" />
                  </div>
                </Col>
                <Col md={4} className="mb-3">
                  <div>
                    <h3>
                      Fax<span>*</span>
                    </h3>
                    <Form.Control required type="text" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BorrowerInfoDetails;
