import React from "react";
import "../CSS/table.css";
import DueOverDataTable from "../Component/table";
import { Badge, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
const ApprovedCasesData = () => {
  const rowData = [
    {
      caseNumber: "3745663",
      assignmentType: "Involuntary repo",
      debtorName: "Dwane Smith",
      client: "County Auto Broker INC.,",
      vahicalInformation: "2004 Chevrolet Colorado",
      vinNumber: "123456789",
      lienHolder: "Credit Acceptance",
      assignTo: "Brian Whatt",
      approval: "Approved",
      Action: "Edit",
    },
    {
        caseNumber: "3745663",
        assignmentType: "Involuntary repo",
        debtorName: "Dwane Smith",
        client: "County Auto Broker INC.,",
        vahicalInformation: "2004 Chevrolet Colorado",
        vinNumber: "123456789",
        lienHolder: "Credit Acceptance",
        assignTo: "Brian Whatt",
        approval: "Approved",
        Action: "Edit",
      },
      {
        caseNumber: "3745663",
        assignmentType: "Involuntary repo",
        debtorName: "Dwane Smith",
        client: "County Auto Broker INC.,",
        vahicalInformation: "2004 Chevrolet Colorado",
        vinNumber: "123456789",
        lienHolder: "Credit Acceptance",
        assignTo: "Brian Whatt",
        approval: "Approved",
        Action: "Edit",
      },
      {
        caseNumber: "3745663",
        assignmentType: "Involuntary repo",
        debtorName: "Dwane Smith",
        client: "County Auto Broker INC.,",
        vahicalInformation: "2004 Chevrolet Colorado",
        vinNumber: "123456789",
        lienHolder: "Credit Acceptance",
        assignTo: "Brian Whatt",
        approval: "Approved",
        Action: "Edit",
      },
      {
        caseNumber: "3745663",
        assignmentType: "Involuntary repo",
        debtorName: "Dwane Smith",
        client: "County Auto Broker INC.,",
        vahicalInformation: "2004 Chevrolet Colorado",
        vinNumber: "123456789",
        lienHolder: "Credit Acceptance",
        assignTo: "Brian Whatt",
        approval: "Approved",
        Action: "Edit",
      },
  ];

  const headingData = (
    <tr>
      <th>Case Number</th>
      <th>Assignment Type</th>
      <th>Debtor Name</th>
      <th>Client</th>
      <th>Vahical Information</th>
      <th>VIN Number</th>
      <th>Lien Holder</th>
      <th>Assign To</th>
      <th>Approval</th>
      <th>Action</th>
    </tr>
  );

  const tableRows = rowData.map((caseItem, index) => (
    <tr key={index}>
      <td>{caseItem.caseNumber}</td>
      <td>
        <div class="select-dropdown">
          <select className="option_like">
            <option value="Option 1">Involuntary repo</option>
            <option value="Option 2">2nd Option</option>
            <option value="Option 3">Option Number</option>
          </select>
        </div>
      </td>
      <td>{caseItem.debtorName}</td>
      <td>{caseItem.client}</td>
      <td>{caseItem.vahicalInformation}</td>
      <td>{caseItem.vinNumber}</td>
      <td>{caseItem.lienHolder}</td>
      <td>{caseItem.assignTo}</td>
      <td>{caseItem.approval}</td>
      <td>
        <NavLink to={"/approved-case-details"}>
        <h4 style={{backgroundColor: "#94C3DB", borderRadius: "4px"}}>
            <Badge bg="#0876AC">
              <i className="fa-regular fa-eye"></i>
            </Badge>
          </h4>
        </NavLink>
      </td>
    </tr>
  ));

  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <DueOverDataTable
                tableRows={tableRows}
                headingData={headingData}
                border={true}
              />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
export default ApprovedCasesData;
