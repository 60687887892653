import React from "react";
import AccordianPageData from "../Component/accordDataBasic";
import { Container } from "react-bootstrap";
const SecuredCaseDetailsData = () => {
  const accorData = [
    {
      id: 1,
      heading: "Client Details",
      data: {
        "Client Phone": "7758777757",
        "Client Fax": "<empty>",
        "Coll Phone": "<empty>",
        "Coll Ext": "<empty>"
      }
    },
    {
      id: 2,
      heading: "Debtors Details",
      data: {
        Debtors: "John Due",
        "Debtors SSN": "<empty>",
        "Debtors DOB": "1998-03-30",
        Cosigner: "allan",
        "Cosigner SSN": "<empty>",
        "Cosigner DOB": "<empty>",
        "Past Due": "<empty>",
        Balance: "$95873"
      }
    },
    {
      id: 3,
      heading: "Vehicle Details",
      data: {
        Year: "2024",
        Make: "MBW",
        Model: "5-Series",
        Vin: "466465675",
        Color: "Blue",
        Plate: "ABC 123",
        "Tag Expire": "2025-04-21",
        mileage: "54KM"
      }
    },
    {
      id: 4,
      heading: "Assign To",
      data: {
        Office: "Mid night express",
        Branch: "unassigned",
        "RDN pro Branch": "N/A",
        "Order Date": "2024-04-21",
        "Case Worker": "Alexander",
        "Case Investigator": "SamSon"
      }
    },
    {
      id: 5,
      heading: "Adjusters Information",
      data: {
        "Adjusters Name": "Andrew Tait",
        "Assigned Date": "2024-03-23",
        "Agent Type": "Company Adviser",
        Phone: "9874756567",
        Status: "Reprocessed",
        "Agent Last Login": "2024-01-26 12:00 AM",
        Email: "absc@gmail.com",
        Fax: "<empty>",
      }
    },
    {
      id: 6,
      heading: "Update",
      data: {
        "Saved To RDN": "2024-01-26 12:00 AM",
        "Last Updated": "by Maria",
        "Update Date/Time": "2024-01-26 12:00 AM",
        Company: "Mid Night Express",
        "Update Type": "(o) Others",
        Details: "REMINDER TO STACEY"
      }
    },
    {
      id: 7,
      heading: "Recovery",
      data: {
        "Date & Time": "2024-01-26 12:00 AM",
        "Recovered At": "2015 COURTHOUSE RDSTAFFORD, VA 22554",
        "Recovered By": "Brian White",
        "Time Zone": "America/New York",
        "Disposition of Collateral": "Stored",
        "Odometer Type": "Digital",
        "Miles on Vehicle": "66353"
      }
    },
  ];
  return (
    <>
          <Container fluid>
            <div className="mt-4">
              <AccordianPageData accorData={accorData} />
            </div>
          </Container>
    </>
  );
};
export default SecuredCaseDetailsData;
