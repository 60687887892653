import React from "react";
import "../CSS/morepage.css";
import { Col, Container, Form, Row } from "react-bootstrap";
const EditFormHeader =()=>{
    return(
        <>
          <Container fluid className="pt-5 mb-3 spans_type">
            <Row>
            <Col md={6} className="mb-3">
                <h3 className="note_update">Form Type<span>*</span></h3>
                <Form.Select aria-label="Default select example">
                  <option>Default</option>
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </Form.Select>
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Save as New Office Form?<span>*</span></h3>
                <Form.Select aria-label="Default select example">
                  <option>Default</option>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </Form.Select>
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Is this form client specific?<span>*</span></h3>
                <Form.Select aria-label="Default select example">
                  <option>Default</option>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </Form.Select>
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Client<span>*</span></h3>
                <Form.Select aria-label="Default select example">
                  <option>Default</option>
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </Form.Select>
              </Col>
            </Row>
        </Container>
        </>
    )
}
export default EditFormHeader;