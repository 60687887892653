import React from "react";
import "../CSS/morepage.css";
import Vectorpic from "../img/Vector.png";
import StatusAndOrderTableData from "./statusAndOrdeTable";
import { Col, Row } from "react-bootstrap";
const SecuredPhotosDocsData = () => {
  return (
    <>
    <div className="status_data12">

      <StatusAndOrderTableData />
    </div>
      <div className="case_complaint">
        <p>Photos</p>
        <div>
          <i className="fa-solid fa-trash delete_trash"></i>{" "}
          <i className="fa-regular fa-pen-to-square"></i>
        </div>
      </div>
      <div className="no_complain mb-5">
        <p className="no_image">No images available for this case</p>
        <div className="image_vector mb-3">
          <input type="file" />
          <img src={Vectorpic} alt="" />
          <p>Upload Pictures</p>
        </div>
        <div>
          <Row>
            <Col md={6}>
              <div>
                <ul>
                  <li>
                    <p className="m-0">
                      You may upload jpg, jpeg, png or gif image files
                    </p>
                  </li>
                  <li>
                    <p>
                      <p>
                        Images will automatically be scaled to approx 640x480
                      </p>
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <ul>
                  <li>
                    <p className="m-0">
                      You may upload several images per case
                    </p>
                  </li>
                  <li>
                    <p>Image file sizes must not exceed 7MB</p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <div className="upload_pic">
          <button>Upload Photos</button>
        </div>
      </div>
      <div className="case_complaint">
        <p>Documents</p>
        <div>
          <i className="fa-solid fa-trash delete_trash"></i>{" "}
          <i className="fa-regular fa-pen-to-square"></i>
        </div>
      </div>
      <div className="no_complain">
        <p className="no_image">No docs available for this case</p>
        <div className="image_vector mb-3">
          <input type="file" />
          <img src={Vectorpic} alt="" />
          <p>Upload Files</p>
        </div>
        <div>
          <Row>
            <Col md={6}>
              <div>
                <ul>
                  <li>
                    <p className="m-0">
                    You may upload several documents per case
                    </p>
                  </li>
                  <li>
                    <p>
                      <p>
                      Document file sizes must not exceed 7MB
                      </p>
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <ul>
                  <li>
                    <p className="m-0">
                      You may upload rtf, pdf, doc, docx, xls, csv, msg, txt, jpg, jpeg, png, gif or tif documents
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <div className="upload_pic">
          <button>Upload File</button>
        </div>
      </div>
    </>
  );
};
export default SecuredPhotosDocsData;
