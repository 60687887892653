import React, { useState } from "react";
import "../CSS/sidebar.css";
// import HeaderPage from "../Component/header";
import UnApprovedpic from "../img/unreview.png";
import Validatepic from "../img/validate.png";
import Approvedpic from "../img/approve.png";
// import Approvedspic from "../img/approvedss.svg";
// import Assignedpic from "../img/assignedwelcome.svg";
import Duespic from "../img/dues.png";
// import Duesspic from "../img/duess.svg";
import Reviewpic from "../img/Group996.svg";
// import Revieedpic from "../img/reviewed.svg";
// import Reviewedpic from "../img/unreviewed.svg";
import History_pic from "../img/history.svg";
// import History_pics from "../img/historyes.svg";
import Logopic from "../img/logosecured1.svg"
import Complainpic from "../img/complain.svg";
import Releasepic from "../img/release.svg";
import Remainpic from "../img/remain.svg";
import Officenote from "../img/officenotes.svg";
import Updatepic from "../img/update.svg";
import { NavLink } from "react-router-dom";

const SidebarPage = () => {
  const [showReviewQueueDropdown, setShowReviewQueueDropdown] = useState(false);
  const [showReviewCasesDropdown, setShowReviewCasesDropdown] = useState(false);
  const [showMoreDropdown, setShowMoreDropdown] = useState(false);
  const [showSecuredDropdown, setShowSecuredDropdown] = useState(false);
  // const [showActive, setShowActive] = useState(false);

  const toggleReviewQueueDropdown = () => {
    setShowReviewQueueDropdown(!showReviewQueueDropdown);
  };
  const toggleReviewCasesDropdown = () => {
    setShowReviewCasesDropdown(!showReviewCasesDropdown);
  };
  const toggleMoreDropdown = () => {
    setShowMoreDropdown(!showMoreDropdown);
  };
  const toggleSecuredDropdown = () => {
    setShowSecuredDropdown(!showSecuredDropdown);
  };

  // const location = useLocation();
  // const handleShowActive=()=>{
  //   setShowActive(true);
  // }
  return (
    <>
      <div className="sidebar-leftsection">
        <div className="MainLogo">
          <img src={Logopic} alt="" />
          {/* <h3>Secured365</h3> */}
        </div>
        <div className="table_name">
          <ul>
            <NavLink to={"/dashboard"}>
              <li>
                <p>
                  <i className="fa-solid fa-house"></i> My Summary
                </p>
              </li>
            </NavLink>
            <NavLink to={"/due-over-cases"}>
              <li>
                <div className="dues_pic_section">
                  <img src={Duespic} alt="" />
                  {/* <img src={Duesspic} alt="" /> */}
                  <p>
                    {/* <i className="fa-regular fa-file-lines"></i>  */}
                    Due/ Over Due Cases
                  </p>
                </div>
              </li>
            </NavLink>
            <li onClick={toggleReviewQueueDropdown}>
              <p>
                {/* <i className="fa-solid fa-book-open"></i>  */}
                <div className="reviewed_pic">
                  <img src={Reviewpic} alt="" />
                  {/* <img src={Revieedpic} alt="" /> */}
                  Review Queue{" "}
                  <i className="fa-solid fa-caret-down down_arrow"></i>
                </div>
              </p>
              {showReviewQueueDropdown && (
                <ul
                  className="dropdown-content slow-open"
                  onClick={(e) => e.stopPropagation()}
                >
                  <NavLink to={"/unassigned-case"}>
                    <li>
                      <div className="image_app">
                        <img src={UnApprovedpic} alt="" />
                        {/* <img src={Reviewedpic} alt="" /> */}
                        <p>Unassigned Cases</p>
                      </div>
                    </li>
                  </NavLink>
                  <NavLink to={"/in-validated-case"}>
                    <li>
                      <div className="image_app">
                        <img src={UnApprovedpic} alt="" />
                        {/* <img src={Reviewedpic} alt="" /> */}
                        <p>Invalidated Cases</p>
                      </div>
                    </li>
                  </NavLink>
                  <NavLink to={"/un-approved-case"}>
                    <li>
                      <div className="image_app">
                        <img src={UnApprovedpic} alt="" />
                        {/* <img src={Reviewedpic} alt="" /> */}
                        <p>Unapproved Cases</p>
                      </div>
                    </li>
                  </NavLink>
                </ul>
              )}
            </li>
            <li onClick={toggleReviewCasesDropdown}>
              <p>
                <div className="reviewed_pic">
                  <img src={Reviewpic} alt="" />
                  {/* <img src={Revieedpic} alt="" /> */}
                  Reviewed Cases{" "}
                  <i className="fa-solid fa-caret-down down_arrow"></i>
                </div>
              </p>
              {showReviewCasesDropdown && (
                <ul className="dropdown-content slow-open">
                  <NavLink to={"/assigned-case"}>
                    <li>
                      <div className="image_app">
                        <img src={Validatepic} alt="" />
                        {/* <img src={Assignedpic} alt="" /> */}
                        <p>Assigned Cases</p>
                      </div>
                    </li>
                  </NavLink>
                  <NavLink to={"/validated-case"}>
                    <li>
                      <div className="image_app">
                        <img src={Approvedpic} alt="" />
                        {/* <img src={Approvedspic} alt="" /> */}
                        <p>Validated Cases</p>
                      </div>
                    </li>
                  </NavLink>
                  <NavLink to={"/approved-case"}>
                    <li>
                      <div className="image_app">
                        <img src={Approvedpic} alt="" />
                        {/* <img src={Approvedspic} alt="" /> */}
                        <p>Approved Cases</p>
                      </div>
                    </li>
                  </NavLink>
                </ul>
              )}
            </li>
            <li onClick={toggleSecuredDropdown}>
              <p>
                <div className="dues_pic_section">
                  <img src={Duespic} alt="" />
                  {/* <img src={Duesspic} alt="" /> */}
                  {/* <i className="fa-regular fa-file-lines"></i>  */}
                  Secured Cases{" "}
                  <i className="fa-solid fa-caret-down down_arrow"></i>
                </div>
              </p>
              {showSecuredDropdown && (
                <ul className="dropdown-content slow-open">
                  <NavLink to={"/secured-case-details"}>
                    <li>
                      <div className="image_app">
                        <img src={Duespic} alt="" />
                        {/* <img src={Duesspic} alt="" /> */}
                        <p>Case Details</p>
                      </div>
                    </li>
                  </NavLink>
                </ul>
              )}
            </li>
            <NavLink to={"/history"}>
              <li>
                <div className="history_im">
                  <p>
                    {/* <i className="fa-solid fa-clock-rotate-left"></i>  */}
                    <img src={History_pic} alt="" />
                    {/* <img src={History_pics} alt="" /> */}
                    History
                  </p>
                </div>
              </li>
            </NavLink>
            <NavLink to={"/form"}>
              <li>
                <div className="dues_pic_sections1">
                  <p>
                    <img src={Duespic} alt="" />
                    {/* <img src={Duesspic} alt="" /> */}
                    {/* <i className="fa-regular fa-file-lines"></i>  */}
                    Form
                  </p>
                </div>
              </li>
            </NavLink>
            <li onClick={toggleMoreDropdown}>
              <p>
                <div className="reviewed_pic">
                  <img src={Reviewpic} alt="" />
                  {/* <img src={Revieedpic} alt="" /> */}
                  More <i className="fa-solid fa-caret-down down_arrow"></i>
                </div>
              </p>
              {showMoreDropdown && (
                <ul className="dropdown-content slow-open">
                  <NavLink to={"/complaint-case"}>
                    <li>
                      <p>
                        <div className="history_im">
                          <img src={Complainpic} alt="" />
                          {/* <i className="fa-solid fa-file-circle-question"></i> */}
                          Complaints
                        </div>
                      </p>
                    </li>
                  </NavLink>
                  <NavLink to={"/reminder-case-details"}>
                    <li>
                      <p>
                        <div className="history_im">
                          <img src={Remainpic} alt="" />
                          {/* <i className="fa-solid fa-clock"></i> */}
                          Reminder
                        </div>
                      </p>
                    </li>
                  </NavLink>
                  <NavLink to={"/recovery-case-details"}>
                    <li>
                      <p>
                        <i className="fa-solid fa-car"></i>Recovery Details
                      </p>
                    </li>
                  </NavLink>
                  <NavLink to={"/release-case-details"}>
                    <li>
                      <p>
                        <div className="history_im">
                          <img src={Releasepic} alt="" />
                          {/* <i className="fa-solid fa-file-circle-question"></i> */}
                          Release Details
                        </div>
                      </p>
                    </li>
                  </NavLink>
                  <NavLink to={"/skip-tracing"}>
                    <li>
                      <p>
                        <i className="fa-solid fa-fingerprint"></i>Skip Tracing
                      </p>
                    </li>
                  </NavLink>
                  <NavLink to={"/updates"}>
                    <li>
                      <p>
                        <div className="history_im">
                          <img src={Updatepic} alt="" />
                          {/* <i className="fa-solid fa-file-circle-question"></i> */}
                          Updates
                        </div>
                      </p>
                    </li>
                  </NavLink>
                  <NavLink to={"/office-notes"}>
                    <li>
                      <p>
                        <div className="history_im">
                          <img src={Officenote} alt="" />
                          {/* <i className="fa-solid fa-file-circle-question"></i> */}
                          Office Notes
                        </div>
                      </p>
                    </li>
                  </NavLink>
                </ul>
              )}
            </li>
            <NavLink to={"/"}>
              <li>
                {/* <div className="sign_out"> */}
                {/* <i className="fa-solid fa-right-from-bracket"></i>  */}

                <p className="sign_outs">
                  <i className="fa-solid fa-user "></i>Log Out
                </p>

                {/* </div> */}
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
    </>
  );
};
export default SidebarPage;
