import React from "react";
import "../CSS/footer.css";
const FooterPage = () => {
  return (
    <>
      <div className="footer">
        {/* <div className="left_footer">
          <h3>Secured365</h3>
        </div>
        <div className="middle">
          <ul>
            <li>
              <a href="/terms">Terms and Conditions</a>
            </li>
            <li>
              <a href="/privacy">@copyright 2001-2024 Business Value Inc</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
          </ul>
        </div> */}
        {/* <div className="right_footer">
          @copyright 2001-2024 Business Value Inc
        </div> */}
        <div className="copy_right">
         <p> @copyright 2001-2024 Business Value Inc</p>
        </div>
      </div>
    </>
  );
};
export default FooterPage;
