import React, { useEffect, useState, useRef } from "react";
import Chart from "chart.js/auto";
import "../../CSS/charts.css";

const CaseStatusOverview = ({ chartData, backgroundColor, graphHeading, totalData }) => {
  const [legendHtml, setLegendHtml] = useState("");
  const chartRef = useRef(null);

  useEffect(() => {
    const visitorData = [];
    const visitData = [];
    let sum = 0;

    for (let i = 0; i < chartData.length; i++) {
      visitorData.push(chartData[i]["visitor"]);
      visitData.push(chartData[i]["visit"]);
      sum += chartData[i]["visitor"];
    }

    const textInside = sum.toString();

    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      const myChart = new Chart(ctx, {
        type: "doughnut",
        animation: {
          animateScale: true,
        },
        data: {
          labels: visitData,
          datasets: [
            {
              label: "Visitor",
              data: visitorData,
              backgroundColor: backgroundColor,
            },
          ],
        },
        options: {
          elements: {
            center: {
              text: textInside,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          legend: false,
          tooltips: {
            enabled: true,
            mode: "label",
            callbacks: {
              label: (tooltipItem, data) => {
                const indice = tooltipItem.index;
                return (
                  data.datasets[0].data[indice] +
                  " person visited " +
                  data.labels[indice] +
                  " times"
                );
              },
            },
          },
        },
      });

      const legendHtmlArray = [];
      legendHtmlArray.push("<ul>");
      const item = myChart.data.datasets[0];
      for (let i = 0; i < item.data.length; i++) {
        legendHtmlArray.push("<li>");
        legendHtmlArray.push(
          '<span className="chart-legend" style="background-color:' +
            item.backgroundColor[i] +
            '"></span>'
        );
        legendHtmlArray.push(
          '<span className="chart-legend-label-text">' +
            item.data[i] +
            "% - " +
            myChart.data.labels[i] 
        );
        legendHtmlArray.push("</li>");
      }
      legendHtmlArray.push("</ul>");
      setLegendHtml(legendHtmlArray.join(""));

      return () => {
        myChart.destroy();
      };
    }
  }, [chartData, backgroundColor]);

  return (
    <>
      <div className="canvas-con">
        <h2>{graphHeading}</h2>
        <div className="hstack justify-content-center align-items-center">
          <div className="canvas-con-inner">
            <canvas id="mychart" ref={chartRef}></canvas>
          </div>
          <div
            id="my-legend-con"
            className="legend-con"
            dangerouslySetInnerHTML={{ __html: legendHtml }}
          ></div>
          <div className="percentage">
            <p>{totalData}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStatusOverview;
