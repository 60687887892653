import React, { useEffect, useState } from "react";
import "../CSS/morepage.css";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const FormEditModalDetails = ({ status, formData }) => {
  console.log(formData);
  const [showFormData, setShowFormData] = useState(false);
  const handleCloseFormData = () => setShowFormData(false);
  const handleShowFormData = () => setShowFormData(true);
  useEffect(() => {
    setShowFormData(status);
  }, [status]);
  return (
    <>
      <Modal show={showFormData} onHide={handleCloseFormData} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="note_update">
              <b>{formData?.heading}</b>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {Object.entries(formData?.data).map(([key, value]) => (
              <Form.Group
                as={Col}
                md="6"
                className="mb-3"
                controlId={`validationFormik${key}`}
                key={key}
              >
                <Form.Label>
                  <h3 className="note_update">{key}</h3>
                </Form.Label>
                <Form.Control type="text" name={key} value={value} />
              </Form.Group>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseFormData}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCloseFormData}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default FormEditModalDetails;
