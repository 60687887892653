import React, { useEffect, useState } from "react";
import "../CSS/dashboard.css";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import HeaderPage from "../Component/header";
import CaseStatusOverview from "../Component/Charts/casestatus";
import Dhonipic from "../img/dhoni.webp";
import { NavLink } from "react-router-dom";
import ColumnChart from "../Component/Charts/columnchart";
import TeamWorkLoad from "../Component/teamworkload";
import MonhtlyOverview from "../Component/Charts/monthwiseoverview";
import SidebarPage from "../Component/Sidebar";
import Duepic from "../img/duewelcome.svg";
import Assignedpic from "../img/assignedwelcome.svg";
import Validatepic from "../img/validatewelcome.svg";
import Approvedpic from "../img/approvedwelcomes.svg";
const DashboardPage = () => {
  const [userData, setUserData] = useState("");
  const [greeting, setGreeting] = useState('');
  // const userData = localStorage.getItem('userData');
  const now = 60;
  const chartData = [
    { visitor: 15, visit: "Due/Over Due Cases"},
    { visitor: 12, visit: "Assigned Cases" },
    { visitor: 9, visit: "Validated Cases" },
    { visitor: 5, visit: "Approved Cases" },
  ];
  const backgroundColor = ["#57D9A3", "#8777D9", "#DDDDEE", "#2684FF"];
  const chartDatapriority = [
    { visitor: 15, visit: "No Priority Cases" },
    { visitor: 12, visit: "Low Priority Cases" },
    { visitor: 9, visit: "Medium Priority Cases" },
    { visitor: 5, visit: "High Priority Cases" },
  ];
  const backgroundColorpriority = ["#DDDDEE", "#57D9A3", "#625894", "#B15858"];

  useEffect(()=>{
    const data = localStorage.getItem('userData');
    if(data){
      setUserData(JSON.parse(data));  }
  },[])

  useEffect(() => {
    const updateGreeting = () => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();

      let newGreeting = '';

      if (currentHour < 12) {
        newGreeting = 'Good Morning';
      } else if (currentHour < 18) {
        newGreeting = 'Good Afternoon';
      } else {
        newGreeting = 'Good Evening';
      }

      setGreeting(newGreeting);
    };
    updateGreeting();
    const intervalId = setInterval(updateGreeting, 60000);

    return () => clearInterval(intervalId);
  }, []); 


  return (
    <>
      <div className="main_wrapper d-flex">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <div className="hello_crist">
                <h3>{greeting}, {userData?.firstName+ " " + userData?.lastName}</h3>
                <p>Welcome to your Trackit Dashboard</p>
              </div>
              <Row>
                <Col md={3} className="mb-5">
                  <div className="all_cases">
                    <img src={Duepic} alt="" />
                    {/* <i className="fa-solid fa-check due_to"></i> */}
                    <div>
                      <div className="valid_days">
                        <h2>30</h2>
                        <p className="m-0">Due/Over Due Cases</p>
                      </div>
                      <p className="last7">In the last 7 days</p>
                    </div>
                  </div>
                </Col>
                <Col md={3} className="mb-5">
                  <div className="all_cases">
                    <img src={Assignedpic} alt="" />
                    {/* <i className="fa-solid fa-pen assigned_to"></i> */}
                    <div>
                      <div className="valid_days">
                        <h2>30</h2>
                        <p className="m-0">Assigned Cases</p>
                      </div>
                      <p className="last7">In the last 7 days</p>
                    </div>
                  </div>
                </Col>
                <Col md={3} className="mb-5">
                  <div className="all_cases">
                    <img src={Validatepic} alt="" />
                    {/* <i className="fa-solid fa-plus validated_to"></i> */}
                    <div>
                      <div className="valid_days">
                        <h2>30</h2>
                        <p className="m-0">Validated Cases</p>
                      </div>
                      <p className="last7">In the last 7 days</p>
                    </div>
                  </div>
                </Col>
                <Col md={3} className="mb-5">
                  <div className="all_cases">
                    <img src={Approvedpic} alt="" />
                    {/* <i className="fa-solid fa-calendar-days approved_to"></i> */}
                    <div>
                      <div className="valid_days">
                        <h2>30</h2>
                        <p className="m-0">Approved Cases</p>
                      </div>
                      <p className="last7">In the last 7 days</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <CaseStatusOverview
                    chartData={chartData}
                    backgroundColor={backgroundColor}
                    graphHeading="Case Status Overview"
                    totalData="57.89%"
                  />
                </Col>
                <Col md={6}>
                  <div className="recent_act">
                    <h2>Recent Activity</h2>
                    <div className="mb-3">
                      <div className="widget-style1 summery__widget">
                        <div className="activity_section">
                          <div className="d-flex flex-wrap mb-2">
                            <div className="circle-icon">
                              <div className="name_icon">R</div>
                            </div>
                            <div className="widget-data">
                              <p className="m-0">
                                Roop Chandra<NavLink to={"/"}> Status </NavLink>
                                changed the status to To Do on{" "}
                              </p>
                              <span>09/02/2024, 16:52:01</span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap mb-2">
                            <div className="circle-icon">
                              <div className="name_icon">R</div>
                            </div>
                            <div className="widget-data">
                              <p className="m-0">
                                Roop Chandra<NavLink to={"/"}> Status </NavLink>
                                changed the status to To Do on{" "}
                              </p>
                              <span>09/02/2024, 16:52:01</span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap mb-2">
                            <div className="circle-icon">
                              <div className="name_icon">R</div>
                            </div>
                            <div className="widget-data">
                              <p className="m-0">
                                Roop Chandra<NavLink to={"/"}> Status </NavLink>
                                changed the status to To Do on{" "}
                              </p>
                              <span>09/02/2024, 16:52:01</span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap mb-2">
                            <div className="circle-icon">
                              <div className="name_icon">R</div>
                            </div>
                            <div className="widget-data">
                              <p className="m-0">
                                Roop Chandra<NavLink to={"/"}> Status </NavLink>
                                changed the status to To Do on{" "}
                              </p>
                              <span>09/02/2024, 16:52:01</span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap mb-2">
                            <div className="circle-icon">
                              <div className="name_icon">R</div>
                            </div>
                            <div className="widget-data">
                              <p className="m-0">
                                Roop Chandra<NavLink to={"/"}> Status </NavLink>
                                changed the status to To Do on{" "}
                              </p>
                              <span>09/02/2024, 16:52:01</span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap mb-2">
                            <div className="circle-icon">
                              <div className="name_icon">R</div>
                            </div>
                            <div className="widget-data">
                              <p className="m-0">
                                Roop Chandra<NavLink to={"/"}> Status </NavLink>
                                changed the status to To Do on{" "}
                              </p>
                              <span>09/02/2024, 16:52:01</span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap mb-2">
                            <div className="circle-icon">
                              <div className="name_icon">R</div>
                            </div>
                            <div className="widget-data">
                              <p className="m-0">
                                Roop Chandra<NavLink to={"/"}> Status </NavLink>
                                changed the status to To Do on{" "}
                              </p>
                              <span>09/02/2024, 16:52:01</span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap mb-2">
                            <div className="circle-icon">
                              <div className="name_icon">R</div>
                            </div>
                            <div className="widget-data">
                              <p className="m-0">
                                Roop Chandra<NavLink to={"/"}> Status </NavLink>
                                changed the status to To Do on{" "}
                              </p>
                              <span>09/02/2024, 16:52:01</span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap mb-2">
                            <div className="circle-icon">
                              <div className="name_icon">R</div>
                            </div>
                            <div className="widget-data">
                              <p className="m-0">
                                Roop Chandra<NavLink to={"/"}> Status </NavLink>
                                changed the status to To Do on{" "}
                              </p>
                              <span>09/02/2024, 16:52:01</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="total_adjust">
                    <div className="adjusters">
                      <h2>Adjusters Available</h2>
                      {/* <button>View All</button> */}
                    </div>
                    <div className="whole_adjust1">
                      <div className="right_adjust">
                        <div className="name_sideadd">
                          <img
                            className="profile-picadd"
                            src={Dhonipic}
                            alt=""
                          />
                          <div>
                            <h6 className="m-0">Satish Kumar</h6>
                            <p>NewYork USA</p>
                          </div>
                        </div>
                        <div>
                          <i className="fa-solid fa-circle"></i>
                        </div>
                      </div>
                      <div className="right_adjust">
                        <div className="name_sideadd">
                          <img
                            className="profile-picadd"
                            src={Dhonipic}
                            alt=""
                          />
                          <div>
                            <h6 className="m-0">Satish Kumar</h6>
                            <p>NewYork USA</p>
                          </div>
                        </div>
                        <div>
                          <i className="fa-solid fa-circle"></i>
                        </div>
                      </div>
                      <div className="right_adjust">
                        <div className="name_sideadd">
                          <img
                            className="profile-picadd"
                            src={Dhonipic}
                            alt=""
                          />
                          <div>
                            <h6 className="m-0">Satish Kumar</h6>
                            <p>NewYork USA</p>
                          </div>
                        </div>
                        <div>
                          <i className="fa-solid fa-circle"></i>
                        </div>
                      </div>
                      <div className="right_adjust">
                        <div className="name_sideadd">
                          <img
                            className="profile-picadd"
                            src={Dhonipic}
                            alt=""
                          />
                          <div>
                            <h6 className="m-0">Satish Kumar</h6>
                            <p>NewYork USA</p>
                          </div>
                        </div>
                        <div>
                          <i className="fa-solid fa-circle"></i>
                        </div>
                      </div>
                      <div className="right_adjust">
                        <div className="name_sideadd">
                          <img
                            className="profile-picadd"
                            src={Dhonipic}
                            alt=""
                          />
                          <div>
                            <h6 className="m-0">Satish Kumar</h6>
                            <p>NewYork USA</p>
                          </div>
                        </div>
                        <div>
                          <i className="fa-solid fa-circle"></i>
                        </div>
                      </div>
                      <div className="right_adjust">
                        <div className="name_sideadd">
                          <img
                            className="profile-picadd"
                            src={Dhonipic}
                            alt=""
                          />
                          <div>
                            <h6 className="m-0">Satish Kumar</h6>
                            <p>NewYork USA</p>
                          </div>
                        </div>
                        <div>
                          <i className="fa-solid fa-circle"></i>
                        </div>
                      </div>
                      <div className="right_adjust">
                        <div className="name_sideadd">
                          <img
                            className="profile-picadd"
                            src={Dhonipic}
                            alt=""
                          />
                          <div>
                            <h6 className="m-0">Satish Kumar</h6>
                            <p>NewYork USA</p>
                          </div>
                        </div>
                        <div>
                          <i className="fa-solid fa-circle"></i>
                        </div>
                      </div>
                      <div className="right_adjust">
                        <div className="name_sideadd">
                          <img
                            className="profile-picadd"
                            src={Dhonipic}
                            alt=""
                          />
                          <div>
                            <h6 className="m-0">Satish Kumar</h6>
                            <p>NewYork USA</p>
                          </div>
                        </div>
                        <div>
                          <i className="fa-solid fa-circle"></i>
                        </div>
                      </div>
                      <div className="right_adjust">
                        <div className="name_sideadd">
                          <img
                            className="profile-picadd"
                            src={Dhonipic}
                            alt=""
                          />
                          <div>
                            <h6 className="m-0">Satish Kumar</h6>
                            <p>NewYork USA</p>
                          </div>
                        </div>
                        <div>
                          <i className="fa-solid fa-circle"></i>
                        </div>
                      </div>
                      <div className="right_adjust">
                        <div className="name_sideadd">
                          <img
                            className="profile-picadd"
                            src={Dhonipic}
                            alt=""
                          />
                          <div>
                            <h6 className="m-0">Satish Kumar</h6>
                            <p>NewYork USA</p>
                          </div>
                        </div>
                        <div>
                          <i className="fa-solid fa-circle"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="resolved_cases">
                    <div className="cases_bar">
                      <div>
                        {/* <input type="checkbox" />  */}
                        <h2>Cases Resolved This Week</h2>
                      </div>
                      <div className="divide">
                        <p>28/98</p>
                      </div>
                    </div>
                    <div>
                      <ProgressBar now={now} label={`${now}%`} />
                    </div>
                  </div>
                  <div>
                    <CaseStatusOverview
                      chartData={chartDatapriority}
                      backgroundColor={backgroundColorpriority}
                      graphHeading="Reminder Priority Overview"
                      totalData="50.57%"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ColumnChart />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <MonhtlyOverview />
                </Col>
                <Col md={6}>
                  <TeamWorkLoad />
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
export default DashboardPage;
