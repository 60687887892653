import React, { useState } from "react";
import "../CSS/morepage.css";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
import StatusAndOrderTableData from "../Component/statusAndOrdeTable";
import { Col, Container, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
const SkipTracingPageDetails = () => {
  const [showEditSkip, setShowEditSkip] = useState(false);

  const handleCloseSkip = () => setShowEditSkip(false);
  const handleShowSkip = () => setShowEditSkip(true);
  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <StatusAndOrderTableData />
              <Tabs
                defaultActiveKey="Skip Notes"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="Skip Notes" title="Skip Notes">
                  <div className="case_complaint">
                    <p>Date & Time</p>
                    <p>Category</p>
                    <p>For</p>
                    <p>Note</p>
                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={handleShowSkip}
                    ></i>
                  </div>
                  <div className="no_complain">
                    <div className="stacy_call">
                      <p>2024-03-20 12:20PM</p>
                      <p>Property Appraiser Address</p>
                      <p>Mark Waugh</p>
                      <p>CLIENT WOULD LIKE A CALL</p>
                      <p></p>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Add New Skip Notes" title="Add New Skip Notes">
                  <div className="case_complaint">
                    <p>Date & Time</p>
                    <p>Category</p>
                    <p>For</p>
                    <p>Note</p> 
                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={handleShowSkip}
                    ></i>
                  </div>
                  <div className="no_complain">
                    <div className="stacy_call">
                      <p>2024-03-20 12:20PM</p>
                      <p>Property Appraiser Address</p>
                      <p>Mark Waugh</p>
                      <p>CLIENT WOULD LIKE A CALL AS SOON AS PAYMENT.</p>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="no_complain">
                      <div>
                        <Row className="mb-3">
                          <Col md={4}>
                            <h3>Date & Time</h3>
                            <div className="date_time_set">
                              <Form.Control required type="date" />
                              <Form.Control required type="time" />
                            </div>
                          </Col>
                          <Col md={4}>
                            <h3>Category</h3>
                            <Form.Select aria-label="Select One">
                              <option>Select One</option>
                              <option value="Ran DMV">Ran DMV</option>
                              <option value="Property Appraiser Address">
                                Property Appraiser Address
                              </option>
                              <option value="Clerk Of Courts Address">
                                Clerk Of Courts Address
                              </option>
                              <option value="Water Verification">
                                Water Verification
                              </option>
                              <option value="Power Verification">
                                Power Verification
                              </option>
                              <option value="IRB Search ">IRB Search </option>
                              <option value="eCollect Search">
                                eCollect Search
                              </option>
                              <option value="Phone Reserve">
                                Phone Reserve
                              </option>
                              <option value="POE Serach">POE Serach</option>
                              <option value="POE Phone Reverse">
                                POE Phone Reverse
                              </option>
                              <option value="Relatives">Relatives</option>
                              <option value="411">411</option>
                              <option value="Google Debtor">
                                Google Debtor
                              </option>
                              <option value="Ran Sat Map of Home">
                                Ran Sat Map of Home
                              </option>
                              <option value="Contacted Neighbors">
                                Contacted Neighbors
                              </option>
                              <option value="Worked Ref Sheets">
                                Worked Ref Sheets
                              </option>
                            </Form.Select>
                          </Col>
                          <Col md={4}>
                            <h3>Case Worker</h3>
                            <Form.Select aria-label="Select One">
                              <option> Select One</option>
                              <option value="ADMIN, CASEWORKER [552708]">
                                ADMIN, CASEWORKER [552708]
                              </option>
                              <option value="Alexander, Kathy [552720]">
                                Alexander, Kathy [552720]
                              </option>
                              <option value="Douglas, Raven [552718]">
                                Douglas, Raven [552718]
                              </option>
                              <option value="Petrea, Kristie [552729]">
                                Petrea, Kristie [552729]
                              </option>
                              <option value="Prodoehl, Susan [552722]">
                                Prodoehl, Susan [552722]
                              </option>
                              <option value="Test, User1 [552750]">
                                Test, User1 [552750]
                              </option>
                              <option value="Willete, Stacey [552700]">
                                Willete, Stacey [552700]
                              </option>
                            </Form.Select>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h3>Notes</h3>
                            <Form.Control
                              as="textarea"
                              style={{ height: "100px" }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <div className="create_button">
                              <button>Create</button>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="create_button">
                              <button>Cancel</button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Container>
        </div>
      </div>
      <Modal
        show={showEditSkip}
        onHide={handleCloseSkip}
        className="modal_type custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="note_update">
              <b>Edit Skip Tracing</b>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="edit_notes_office">
            <Row className="mb-3">
              <Col md={4} className="mb-3">
                <h3 className="note_update">Date & Time</h3>
                <div className="date_time_set">
                  <Form.Control required type="date" />
                  <Form.Control required type="time" />
                </div>
              </Col>
              <Col md={8} className="mb-3">
                <h3 className="note_update">Category</h3>
                <Form.Select aria-label="Select One">
                  <option>Select One</option>
                  <option value="Ran DMV">Ran DMV</option>
                  <option value="Property Appraiser Address">
                    Property Appraiser Address
                  </option>
                  <option value="Clerk Of Courts Address">
                    Clerk Of Courts Address
                  </option>
                  <option value="Water Verification">Water Verification</option>
                  <option value="Power Verification">Power Verification</option>
                  <option value="IRB Search ">IRB Search </option>
                  <option value="eCollect Search">eCollect Search</option>
                  <option value="Phone Reserve">Phone Reserve</option>
                  <option value="POE Serach">POE Serach</option>
                  <option value="POE Phone Reverse">POE Phone Reverse</option>
                  <option value="Relatives">Relatives</option>
                  <option value="411">411</option>
                  <option value="Google Debtor">Google Debtor</option>
                  <option value="Ran Sat Map of Home">
                    Ran Sat Map of Home
                  </option>
                  <option value="Contacted Neighbors">
                    Contacted Neighbors
                  </option>
                  <option value="Worked Ref Sheets">Worked Ref Sheets</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col md={4} className="mb-3">
              <h3 className="note_update">For</h3>
              <Form.Control required type="text" />
              </Col>
              <Col md={8} className="mb-3">
                <h3 className="note_update">Notes</h3>
                <Form.Control as="textarea" style={{ height: "100px" }} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="create_button">
                  <button>Create</button>
                </div>
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Cancel</button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SkipTracingPageDetails;
