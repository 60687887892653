import axios from "axios";
import { sweetAlert } from "../utils/UniversalFunction";

export async function apiCall(url, header = {}, data = {}, method = "GET") {
  let currentUser;
  let config;
  // data = {...data };
  if (localStorage.getItem("currentUser")) {
    currentUser = JSON.parse(localStorage.getItem("currentUser"));
    config = {
      headers: {
        ...header,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        userId: currentUser.id,
        Accept: "application/json",
      },
    };
  } else {
    config = {
      headers: {
        ...header,
        Accept: "application/json",
      },
    };
  }

  try {
    let response;
    if (method.toUpperCase() === "GET") {
      const queryParams = Object.entries(data)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return `${key}=[${value.join(",")}]`;
          }
          return `${key}=${value}`;
        })
        .join("&");
      // response = await axios.get(`${url}${queryParams}`, config);
      response = await axios.get(`${url}?${queryParams}`, config);
    } else {
      response = await axios[method.toLowerCase()](url, data, config);
    }
    const { status, data: responseData } = response;

    if (status === 200) {
      if (method.toUpperCase() !== "GET") {
        if (responseData?.notShowPopUp) {
        } else {
          sweetAlert("success", responseData?.message);
        }
      }
      return { isSuccess: true, data: responseData };
    } else {
      throw new Error(`Request failed with status ${status}`);
    }
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message === "SESSION_EXPIRED"
    ) {
      sweetAlert("error", "Your Session has expired. Please log in again.");
      localStorage.clear();
      window.location.href = "/";
      return;
    } else {
      const errorMessage = error.response
        ? error.response.data.message
        : "An error occurred";
      sweetAlert("error", errorMessage);
      return { isSuccess: false, data: { error: errorMessage } };
    }
  }
}