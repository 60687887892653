import React from "react";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import StatusAndOrderTableData from "../Component/statusAndOrdeTable";
const ValidatedCaseDetailsData = () => {
  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <StatusAndOrderTableData />
              <Tabs
                defaultActiveKey="Verify Address"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="Verify Address" title="Verify Address">
                  <div className="case_complaint">
                    <p>Address 1</p>
                    <p>City</p>
                    <p>Country</p>
                    <p>State</p>
                    <p>Zip</p>
                    <p>Cell</p>
                    <p>Phone</p>
                    <p>Authentication</p>
                    <i className="fa-regular fa-pen-to-square"></i>
                  </div>
                  <div className="no_complain">
                    <div className="add_type1">
                      <p>8665 E. Hartford Dr</p>
                      <p>Scottsdale</p>
                      <p>Maricopa</p>
                      <p>AZ</p>
                      <p>85255</p>
                      <p>602-952-2410</p>
                      <p>602-952-2410</p>
                      <p>Valid</p>
                      <i className="fa-solid fa-trash delete_trash"></i>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Add New Address" title="Add New Address">
                  <div className="case_complaint">
                    <p>Address 2</p>
                    <p>City</p>
                    <p>Country</p>
                    <p>State</p>
                    <p>Zip</p>
                    <p>Cell</p>
                    <p>Phone</p>
                    <p>Authentication</p>
                    <i className="fa-regular fa-pen-to-square"></i>
                  </div>
                  <div className="no_complain">
                    <div className="add_type1">
                      <p>8665 E. Hartford Dr</p>
                      <p>Scottsdale</p>
                      <p>Maricopa</p>
                      <p>AZ</p>
                      <p>85255</p>
                      <p>602-952-2410</p>
                      <p>602-952-2410</p>
                      <p>Valid</p>
                      <i className="fa-solid fa-trash delete_trash"></i>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="case_complaint">
                      <p>Add New Address</p>
                      <i className="fa-solid fa-trash delete_trash"></i>
                    </div>
                    <div className="no_complain">
                      <div>
                        <Row>
                          <Col md={6} className="mb-3">
                            <div>
                              <h3>Address</h3>
                              <Form.Control required type="text" />
                            </div>
                          </Col>
                          <Col md={3} className="mb-3">
                            <div>
                              <h3>City</h3>
                              <Form.Select aria-label="Default select example">
                                <option>Default</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                              </Form.Select>
                            </div>
                          </Col>
                          <Col md={3} className="mb-3">
                            <div>
                              <h3>
                                Country
                              </h3>
                              <Form.Select aria-label="Default select example">
                                <option>Default</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                              </Form.Select>
                            </div>
                          </Col>
                          <Col md={3} className="mb-3">
                            <div>
                              <h3>
                                State
                              </h3>
                              <Form.Select aria-label="Default select example">
                                <option>Default</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                              </Form.Select>
                            </div>
                          </Col>
                          <Col md={3} className="mb-3">
                            <div>
                              <h3>
                                Zip
                              </h3>
                              <Form.Control required type="text" />
                            </div>
                          </Col>
                          <Col md={3} className="mb-3">
                            <div>
                              <h3>
                              Cell
                                </h3>
                                <Form.Control required type="text" />
                            </div>
                          </Col>
                          <Col md={3} className="mb-3">
                            <div>
                              <h3>
                                Phone
                              </h3>

                              <Form.Control required type="text" />
                            </div>
                          </Col>
                          <Col md={3} className="mb-3">
                            <div>
                              <h3>
                               Authentication
                              </h3>
                              <Form.Select aria-label="Default select example">
                                <option>Default</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                              </Form.Select>
                            </div>
                          </Col>
                          <Col md={9} className="mb-3">
                            <div>
                              <h3>
                                Description
                              </h3>
                              <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                          />
                            </div>
                          </Col>
                          <Col md={6}>
                        <div className="create_button">
                          <button>Create</button>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="create_button">
                          <button>Cancel</button>
                        </div>
                      </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Updates" title="Updates">
                  <div className="valid_point">
                    <Row>
                      <Col md={3}>
                        <div>
                          <h4>Saved To RDN</h4>
                          <p>08/23/2017 01:39 PM</p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <h4>Last Updated</h4>
                          <p>By MARLENA JARRELL (User)</p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <h4>Update Data/Time</h4>
                          <p>08/23/2017 01:39 PM</p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <h4>Company</h4>
                          <p>Midnight Express Auto Recovery Inc.</p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <h4>Update Type</h4>
                          <p>(O) Other</p>
                        </div>
                      </Col>
                      <Col md={9}>
                        <h4>Details</h4>
                        <p>
                          REMINDER TO STACEY ABOUT PAYMENT,Not Visible Edit
                          Delete Collector Print
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
export default ValidatedCaseDetailsData;
