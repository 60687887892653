import React from "react";
import { ProgressBar, Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../CSS/teamload.css";

const TeamWorkLoad = () => {
  return (
    <>
      <div className="widget-style1 teamlead_types">
        <h2 className="p-0">Team Workload</h2>
        <div className="activity_section activity_point">
          <Table className="bg-none">
            <thead>
              <th style={{ width: 200 }}>Assignee</th>
              <th>Work Distribution</th>
              <th>Count</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="hstack">
                    <div className="circle-icon">
                      <div
                        className="name_icon m-0"
                        title="dipakcompany@yopmail.com"
                      >
                        D
                      </div>
                    </div>
                    <div className="widget-data">
                      <p className="m-0" title="dipakcompany@yopmail.com">
                        Dipak
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <ProgressBar className="data_set12" now={65} label={"65%"} />
                </td>
                <td>
                  <NavLink to={"/"}>70</NavLink>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="hstack">
                    <div className="circle-icon">
                      <div className="name_icon m-0" title="roop@yopmail.com">
                        RC
                      </div>
                    </div>
                    <div className="widget-data">
                      <p className="m-0" title="roop@yopmail.com">
                        RC Chandra
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <ProgressBar className="data_set12" now={56} label={"56%"} />
                </td>
                <td>
                  <NavLink to={"/"}>70</NavLink>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="hstack">
                    <div className="circle-icon">
                      <div className="name_icon m-0" title="roop@yopmail.com">
                        RC
                      </div>
                    </div>
                    <div className="widget-data">
                      <p className="m-0" title="roop@yopmail.com">
                        RC Chandra
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <ProgressBar className="data_set12" now={43} label={"43%"} />
                </td>
                <td>
                  <NavLink to={"/"}>70</NavLink>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="hstack">
                    <div className="circle-icon">
                      <div className="name_icon m-0" title="roop@yopmail.com">
                        RC
                      </div>
                    </div>
                    <div className="widget-data">
                      <p className="m-0" title="roop@yopmail.com">
                        RC Chandra
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <ProgressBar className="data_set12" now={81} label={"81%"} />
                </td>
                <td>
                  <NavLink to={"/"}>70</NavLink>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="hstack">
                    <div className="circle-icon">
                      <div className="name_icon m-0" title="roop@yopmail.com">
                        RC
                      </div>
                    </div>
                    <div className="widget-data">
                      <p className="m-0" title="roop@yopmail.com">
                        RC Chandra
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <ProgressBar className="data_set12" now={55} label={"55%"} />
                </td>
                <td>
                  <NavLink to={"/"}>70</NavLink>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="hstack">
                    <div className="circle-icon">
                      <div
                        className="name_icon m-0"
                        title="dipakcompany@yopmail.com"
                      >
                        D
                      </div>
                    </div>
                    <div className="widget-data">
                      <p className="m-0" title="dipakcompany@yopmail.com">
                        Dipak
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <ProgressBar className="data_set12" now={48} label={"48%"} />
                </td>
                <td>
                  <NavLink to={"/"}>70</NavLink>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="hstack">
                    <div className="circle-icon">
                      <div
                        className="name_icon m-0"
                        title="dipakcompany@yopmail.com"
                      >
                        D
                      </div>
                    </div>
                    <div className="widget-data">
                      <p className="m-0" title="dipakcompany@yopmail.com">
                        Dipak
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <ProgressBar className="data_set12" now={76} label={"76%"} />
                </td>
                <td>
                  <NavLink to={"/"}>70</NavLink>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="hstack">
                    <div className="circle-icon">
                      <div className="name_icon m-0" title="roop@yopmail.com">
                        RC
                      </div>
                    </div>
                    <div className="widget-data">
                      <p className="m-0" title="roop@yopmail.com">
                        RC Chandra
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <ProgressBar className="data_set12" now={73} label={"73%"} />
                </td>
                <td>
                  <NavLink to={"/"}>70</NavLink>
                </td>
              </tr>

              <tr>
                <td>
                  <div className="hstack">
                    <div className="circle-icon">
                      <div className="name_icon m-0" title="roop@yopmail.com">
                        RC
                      </div>
                    </div>
                    <div className="widget-data">
                      <p className="m-0" title="roop@yopmail.com">
                        RC Chandra
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <ProgressBar className="data_set12" now={75} label={"75%"} />
                </td>
                <td>
                  <NavLink to={"/"}>70</NavLink>
                </td>
              </tr>
              {/* <td colSpan={3}>
                  <div className="hstack">
                    <div className="circle-icon">
                      <div className="name_icon m-0" title="Invite Teammate">
                        <i className="fas fa-user-plus text-white"></i>
                      </div>
                    </div>
                    <div className="widget-data">
                      <p className="m-0">
                        <NavLink to={"/"}> Invite Teammate</NavLink>
                      </p>
                    </div>
                  </div>
                </td> */}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};
export default TeamWorkLoad;
