import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import LoginPage from "./Component/login";
import DashboardPage from "./Pages/Dashboard";
import FooterPage from "./Component/footer";
import DueOverDuecasesData from "./Pages/Duecases";
import DuesDetailsData from "./Pages/Duesoverdetails";
import UnAssignedCasesData from "./Pages/UnassignedCases";
import InValidatedCasesData from "./Pages/InvalidatedCases";
import UnApprovedCasesData from "./Pages/UnapprovedCases";
import ApprovedCasesData from "./Pages/ApprovedCases";
import ValidatedCasesData from "./Pages/ValidatedCases";
import AssignedCasesData from "./Pages/AssignedCases";
import AssignedCaseDetailsData from "./Pages/AssignCaseDetails";
import ComplaintPageDetails from "./Pages/ComplaintPageData";
import RemainderPageDetails from "./Pages/RemainderPageData";
import RecoveryPageDetails from "./Pages/RecoveryDetailsPageData";
import SkipTracingPageDetails from "./Pages/SkipTracingPageData";
import OfficeNotesPageDetails from "./Pages/OfficeNotesData";
import UserHistoryPageDetails from "./Pages/UserHistoryPageData";
import ReleasePageDetails from "./Pages/ReleaseDetailsPageData";
import FormPageDetails from "./Pages/FormPageDesign";
import SecuredCasePageDetails from "./Pages/CaseDetailsPageData";
import UnAssignedCaseDetailsData from "./Pages/UnassignedCaseDetails";
import ValidatedCaseDetailsData from "./Pages/ValidateCasesDetails";
import UpdatePageDetails from "./Pages/UpdatePageDetails";
import InValidatedCaseDetailsData from "./Pages/InvalidateCaseDetails";
import UnApprovedCaseDetailsData from "./Pages/UnApprovedCasedetails";
import ApprovedCaseDetailsDatas from "./Pages/ApprovedCaseDetailsData";
import AllFormEditDataDetails from "./Pages/AllFormEditSidebar";
// import AccordianFullPageData from "./Pages/Accordianfullpagedata";

function App() {
  const location = useLocation();

  // Determine if current route is the login page
  const isLoginPage = location.pathname === "/";
  return (
    <div className="App">
      {/* <HeaderPage /> */}
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route exact path="/dashboard" element={<DashboardPage />} />
        <Route exact path="/due-over-cases" element={<DueOverDuecasesData />} />
        <Route exact path="/due-over-case-details" element={<DuesDetailsData />} />
        <Route exact path="/unassigned-case" element={<UnAssignedCasesData />} />
        <Route exact path="/unassigned-case-details" element={<UnAssignedCaseDetailsData />} />
        <Route exact path="/assigned-case" element={<AssignedCasesData />} />
        <Route exact path="/assigned-case-details" element={<AssignedCaseDetailsData />} />
        <Route exact path="/in-validated-case" element={<InValidatedCasesData />} />
        <Route exact path="/invalidated-case-details" element={<InValidatedCaseDetailsData />} />
        <Route exact path="/validated-case" element={<ValidatedCasesData />} />
        <Route exact path="/validated-case-details" element={<ValidatedCaseDetailsData />} />
        <Route exact path="/un-approved-case" element={<UnApprovedCasesData />} />
        <Route exact path="/unapproved-case-details" element={<UnApprovedCaseDetailsData />} />
        <Route exact path="/approved-case" element={<ApprovedCasesData />} />
        <Route exact path="/approved-case-details" element={<ApprovedCaseDetailsDatas />} />
        <Route exact path="/secured-case-details" element={<SecuredCasePageDetails />} />
        <Route exact path="/complaint-case" element={<ComplaintPageDetails />} />
        <Route exact path="/reminder-case-details" element={<RemainderPageDetails />} />
        <Route exact path="/recovery-case-details" element={<RecoveryPageDetails />} />
        <Route exact path="/release-case-details" element={<ReleasePageDetails />} />
        <Route exact path="/skip-tracing" element={<SkipTracingPageDetails />} />
        <Route exact path="/updates" element={<UpdatePageDetails />} />
        <Route exact path="/office-notes" element={<OfficeNotesPageDetails />} />
        <Route exact path="/history" element={<UserHistoryPageDetails />} />
        <Route exact path="/form" element={<FormPageDetails />} />
        <Route exact path="/edit-form" element={<AllFormEditDataDetails />} />
        {/* <Route exact path="/approved-case-detils" element={<AccordianFullPageData />} /> */}
      </Routes>
      {!isLoginPage && <FooterPage />}
    </div>
  );
}

export default App;
