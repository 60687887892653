import React from "react";
import "../CSS/table.css";
import DueOverDataTable from "../Component/table";
import { Badge, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
const ValidatedCasesData = () => {
  const rowData = [
    {
      caseNumber: "3745663",
      assignmentType: "Involuntary repo",
      debtorName: "Dwane Smith",
      city: "New York",
      country: "USA",
      address_1: "New Street  24/04 New York",
      address_2: "New Street  24/04 New York",
      lienHolder: "Credit Acceptance",
      vehicleInformation: "Honda Accord",
      vinNumber: "987654321",
      Authentication: "Valid",
      Action: "Edit",
    },
    {
      caseNumber: "3745663",
      assignmentType: "Involuntary repo",
      debtorName: "Dwane Smith",
      city: "New York",
      country: "USA",
      address_1: "New Street  24/04 New York",
      address_2: "New Street  24/04 New York",
      lienHolder: "Credit Acceptance",
      vehicleInformation: "Honda Accord",
      vinNumber: "987654321",
      Authentication: "Valid",
      Action: "Edit",
    },
    {
      caseNumber: "3745663",
      assignmentType: "Involuntary repo",
      debtorName: "Dwane Smith",
      city: "New York",
      country: "USA",
      address_1: "New Street  24/04 New York",
      address_2: "New Street  24/04 New York",
      lienHolder: "Credit Acceptance",
      vehicleInformation: "Honda Accord",
      vinNumber: "987654321",
      Authentication: "Valid",
      Action: "Edit",
    },
    {
      caseNumber: "3745663",
      assignmentType: "Involuntary repo",
      debtorName: "Dwane Smith",
      city: "New York",
      country: "USA",
      address_1: "New Street  24/04 New York",
      address_2: "New Street  24/04 New York",
      lienHolder: "Credit Acceptance",
      vehicleInformation: "Honda Accord",
      vinNumber: "987654321",
      Authentication: "Valid",
      Action: "Edit",
    },
    {
      caseNumber: "3745663",
      assignmentType: "Involuntary repo",
      debtorName: "Dwane Smith",
      city: "New York",
      country: "USA",
      address_1: "New Street  24/04 New York",
      address_2: "New Street  24/04 New York",
      lienHolder: "Credit Acceptance",
      vehicleInformation: "Honda Accord",
      vinNumber: "987654321",
      Authentication: "Valid",
      Action: "Edit",
    },
  ];

  const headingData = (
    <tr>
      <th>Case Number</th>
      <th>Assignment Type</th>
      <th>Debtor Name</th>
      <th>City</th>
      <th>Country</th>
      <th>Address-1</th>
      <th>Address-2</th>
      <th>Lien Holder</th>
      <th>Vehicle Information</th>
      <th>VIN Number</th>
      <th>Authentication</th>
      <th>Action</th>
    </tr>
  );

  const tableRows = rowData.map((caseItem, index) => (
    <tr key={index}>
      <td>{caseItem.caseNumber}</td>
      <td>
        <div class="select-dropdown">
          <select className="option_like">
            <option value="Option 1">Involuntary repo</option>
            <option value="Option 2">2nd Option</option>
            <option value="Option 3">Option Number</option>
          </select>
        </div>
      </td>
      <td>{caseItem.debtorName}</td>
      <td>{caseItem.city}</td>
      <td>{caseItem.country}</td>
      <td>{caseItem.address_1}</td>
      <td>{caseItem.address_2}</td>
      <td>{caseItem.lienHolder}</td>
      <td>{caseItem.vehicleInformation}</td>
      <td>{caseItem.vinNumber}</td>
      <td className="valid">{caseItem.Authentication}</td>
      <td>
        <NavLink to={"/validated-case-details"}>
        <h4 style={{backgroundColor: "#94C3DB", borderRadius: "4px"}}>
            <Badge bg="#0876AC">
              <i className="fa-regular fa-eye"></i>
            </Badge>
          </h4>
        </NavLink>
      </td>
    </tr>
  ));

  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <DueOverDataTable
                tableRows={tableRows}
                headingData={headingData}
                border={true}
              />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
export default ValidatedCasesData;
