import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Button, Form, Modal } from "react-bootstrap";

const ColumnChart = (props) => {
  const [showYearData, setShowYearData] = useState(false);

  const handleCloseYear = () => setShowYearData(false);
  const handleShowYear = () => setShowYearData(true);
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Due/Over Due Cases",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 63, 79, 59],
      },
      {
        name: "Approved Cases",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 112, 95, 93],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "58%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
      fill: {
        opacity: 1,
      },
      // title: {
      //   text: 'Column View',
      // },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
      colors: ["#0876AC", "#625894"],
    },
  });
  useEffect(() => {
    if (props?.chartData) {
      setChartData(props?.chartData);
    }
  }, [props]);

  return (
    <div id="chart">
      <div className="column_chart">
        <div className="year_viewchart">
          <h2>Yearly Overview</h2>
          <i onClick={handleShowYear} className="fa-solid fa-filter"></i>
        </div>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={450}
        />
      </div>
      {/* <div className="series-names">
          <p style={{ color: "#0876AC" }}>Due/Over Due Cases</p>
          <p style={{ color: "#0876AC" }}>Approved Cases</p>
        </div> */}
      <Modal show={showYearData} onHide={handleCloseYear} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title><h2 className="year_datacol">Yearly Overview Filter</h2></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="find_here">You can find here year wise data</div>
          <Form.Select >
            {/* <option>Open this select menu</option> */}
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
          </Form.Select>
        </Modal.Body>
        <Modal.Footer className="save_change">
          <Button onClick={handleCloseYear}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ColumnChart;
