import React, { useState } from "react";
import "../CSS/morepage.css";
import StatusAndOrderTableData from "../Component/statusAndOrdeTable";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
const ComplaintPageDetails = () => {
  const [showEditComplain, setShowEditComplain] = useState(false);

  const handleCloseComplain = () => setShowEditComplain(false);
  const handleShowComplain = () => setShowEditComplain(true);
  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <StatusAndOrderTableData />
              <div className="case_complaint">
                <p>Case Complaint</p>
                <i className="fa-regular fa-pen-to-square"></i>
              </div>
              <div className="no_complain">
                <p>No Complaint file</p>
              </div>
              <div className="mt-5">
                <div className="case_complaint">
                  <p>Add New Complain</p>
                  <i
                    className="fa-regular fa-pen-to-square"
                    onClick={handleShowComplain}
                  ></i>
                </div>
                <div className="no_complain">
                  <div>
                    <Row>
                      <Col md={3} className="mb-3">
                        <h3>Customer Acknowledged On</h3>
                        <Form.Control required type="date" />
                      </Col>
                      <Col md={3} className="mb-3">
                        <h3>Type</h3>
                        <Form.Select aria-label="Default select example">
                          <option>Title Received</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </Col>
                      <Col md={3} className="mb-3">
                        <h3>Submitted By Name</h3>
                        <Form.Control required type="text" />
                      </Col>
                      <Col md={3} className="mb-3">
                        <h3>High Profile</h3>
                        <Form.Select aria-label="Default select example">
                          <option>Title Received</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </Col>
                      <Col md={3} className="mb-3">
                        <h3>Method Receives </h3>
                        <Form.Select aria-label="Default select example">
                          <option>Title Received</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </Col>
                      <Col md={3} className="mb-3">
                        <h3>Organization Involved</h3>
                        <Form.Select aria-label="Default select example">
                          <option>Title Received</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </Col>
                      <Col md={3} className="mb-3">
                        <h3>Received On</h3>
                        <Form.Control required type="date" />
                      </Col>
                      <Col md={3} className="mb-3">
                        <h3>Status</h3>
                        <Form.Select aria-label="Default select example">
                          <option>Title Received</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h3>Description</h3>
                        <Form.Control
                          as="textarea"
                          style={{ height: "100px" }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="create_button">
                          <button>Create</button>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="create_button">
                          <button>Cancel</button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Modal
        show={showEditComplain}
        onHide={handleCloseComplain}
        className="modal_type custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="note_update">
              <b>Edit New Complaint</b>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="edit_notes_office">
            <Row className="mb-3">
              <Col md={6} className="mb-3">
                <h3 className="note_update">Customer Acknowledged On</h3>
                <div className="date_time_set">
                  <Form.Control required type="date" />
                </div>
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Type</h3>
                <Form.Select aria-label="Default select example">
                  <option>Title Received</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Submitted By Name</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">High Profile</h3>
                <Form.Select aria-label="Default select example">
                  <option>Title Received</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Method Receives</h3>
                <Form.Select aria-label="Default select example">
                  <option>Title Received</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Organization Involved</h3>
                <Form.Select aria-label="Default select example">
                  <option>Title Received</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Received On</h3>
                <Form.Control required type="date" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Status</h3>
                <Form.Select aria-label="Default select example">
                  <option>Title Received</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Create</button>
                </div>
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Cancel</button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ComplaintPageDetails;
