import React, { useState } from "react";
import "../CSS/morepage.css";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
import StatusAndOrderTableData from "../Component/statusAndOrdeTable";
import { Col, Container, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const RecoveryPageDetails = () => {
  const [showEditRecoveryInfo, setShowEditRecoveryInfo] = useState(false);
  const [showEditConditionKey, setShowEditConditionKey] = useState(false);
  const [showEditPoliceInfo, setShowEditPoliceInfo] = useState(false);
  const [showEditStorageInfo, setShowEditStorageInfo] = useState(false);

  const handleCloseRecoveryInfo = () => setShowEditRecoveryInfo(false);
  const handleShowRecoveryInfo = () => setShowEditRecoveryInfo(true);

  const handleCloseConditionKey = () => setShowEditConditionKey(false);
  const handleShowConditionKey = () => setShowEditConditionKey(true);

  const handleClosePoliceInfo = () => setShowEditPoliceInfo(false);
  const handleShowPoliceInfo = () => setShowEditPoliceInfo(true);

  const handleCloseStorageInfo = () => setShowEditStorageInfo(false);
  const handleShowStorageInfo = () => setShowEditStorageInfo(true);

  const navigate = useNavigate();

  const handleTabSelect = (eventKey) => {
    if (eventKey === "Form") {
      navigate("/form");
    }
  };
  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <StatusAndOrderTableData />
              <Tabs
                defaultActiveKey="Recovery Information"
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={handleTabSelect}
              >
                <Tab
                  eventKey="Recovery Information"
                  title="Recovery Information"
                >
                  <div className="case_complaint">
                    <p>Date & Time</p>
                    <p>Recovered At</p>
                    <p>Recovered By</p>
                    <p>Time Zone</p>
                    <p>Disposition of Collateral</p>
                    <p>Odeometer Type</p>
                    <p>Miles On Vehical</p>
                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={handleShowRecoveryInfo}
                    ></i>
                  </div>
                  <div className="no_complain">
                    <div className="stacy_call">
                      <p>2024-03-20 12:20PM</p>
                      <p>Stacy Well(user)</p>
                      <p>Mark Waugh</p>
                      <p>2024-03-20 12:20PM</p>
                      <p>Stacy Well(user)</p>
                      <p>Mark Waugh</p>
                      <p>57756</p>
                      <p></p>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Condition & Keys" title="Condition & Keys">
                  <div className="case_complaint">
                    <p>Condition & Keys</p>
                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={handleShowConditionKey}
                    ></i>
                  </div>
                  <div className="no_complain">
                    <div className="whole_condition">
                      <Row>
                        <Col md={2}>
                          <div>
                            <h3>Overall Condition</h3>
                            <p>Average</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Drivable</h3>
                            <p>Yes</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Ignition Type</h3>
                            <p>empty</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Trunk Access</h3>
                            <p>empty</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Damage</h3>
                            <p>Yes</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Keys</h3>
                            <p>Yes- Obtained from Customer</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Key Count</h3>
                            <p>empty</p>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div>
                            <h3>Interior Vehicle Access</h3>
                            <p>empty</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Est. Damage</h3>
                            <p>empty</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Key Type</h3>
                            <p>ohter</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Remote Count </h3>
                            <p>empty</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Police Information" title="Police Information">
                  <div className="case_complaint">
                    <p>Police Information</p>
                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={handleShowPoliceInfo}
                    ></i>
                  </div>
                  <div className="no_complain">
                    <div className="whole_condition">
                      <Row>
                        <Col md={2}>
                          <div>
                            <h3>Police Report</h3>
                            <p>#C39</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Police Time Zone</h3>
                            <p>America/New york</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Police Notified On</h3>
                            <p>08/22/2017 20:45</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Badge#/Name</h3>
                            <p>#C39</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Police Agency</h3>
                            <p>STAFFORD COUNTY SHERIFF</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Police Address</h3>
                            <p>empty</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>City</h3>
                            <p>NewYork</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>State</h3>
                            <p>local</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Zip</h3>
                            <p>088539</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Police Branch</h3>
                            <p>empty</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Police Updates E-mail </h3>
                            <p>empty</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Police Updates Phone </h3>
                            <p>745665646</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Police Fax </h3>
                            <p>empty</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Police Toll-free </h3>
                            <p>empty</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Police Transport Notes </h3>
                            <p>empty</p>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div>
                            <h3>Police Additional Info </h3>
                            <p>Additional Information</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Storage Information" title="Storage Information">
                  <div className="case_complaint">
                    <p>Storage Information</p>
                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={handleShowStorageInfo}
                    ></i>
                  </div>
                  <div className="no_complain">
                    <div className="whole_condition">
                      <Row>
                        <Col md={3}>
                          <div>
                            <h3>Storage Location</h3>
                            <p>MEAR-FREDERICKSBURG LOT</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <h3>Address</h3>
                            <p>47 COOL SPRING RD FREDERICKSBURG, VA 22405</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Lot Space Number</h3>
                            <p>empty</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Phone</h3>
                            <p>703-445-8698 </p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <h3>Hours of Operation</h3>
                            <p>empty</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="Condition Reports Status"
                  title="Condition Reports Status"
                >
                  <div className="case_complaint">
                    <p>Condition Report Status</p>
                    <i className="fa-regular fa-pen-to-square"></i>
                  </div>
                  <div className="no_complain">
                    <div className="whole_condition">
                      <Row>
                        <Col>
                          <div>
                            <h3>Standard Truck</h3>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Form" title="Form"></Tab>
              </Tabs>
            </div>
          </Container>
        </div>
      </div>
      <Modal
        show={showEditRecoveryInfo}
        onHide={handleCloseRecoveryInfo}
        className="modal_type custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="note_update">
              <b>Edit Recovery Information</b>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="edit_notes_office">
            <Row className="mb-3">
              <Col md={4} className="mb-3">
                <h3 className="note_update">Date & Time</h3>
                <div className="date_time_set">
                  <Form.Control required type="date" />
                  <Form.Control required type="time" />
                </div>
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Recovered At</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Recovered By</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Time Zone</h3>
                <Form.Control required type="time" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Disposition of Collateral</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Odeometer Type</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={12} className="mb-3">
                <h3 className="note_update">Miles On Vehical</h3>
                <Form.Control required type="text" />
              </Col>

              <Col md={6}>
                <div className="create_button">
                  <button>Create</button>
                </div>
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Cancel</button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showEditConditionKey}
        onHide={handleCloseConditionKey}
        className="modal_type custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="note_update">
              <b>Edit Condition & Key</b>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="edit_notes_office">
            <Row className="mb-3">
              <Col md={4} className="mb-3">
                <h3 className="note_update">Overall Condition</h3>
                <div className="date_time_set">
                  <Form.Control required type="text" />
                </div>
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Drivable</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Ignition Type</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Trunk Access</h3>
                <Form.Control required type="time" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Damage</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Keys</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Key Count</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Interior Vehicle Access</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Est. Damage</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Key Type</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Remote Count</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Create</button>
                </div>
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Cancel</button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showEditPoliceInfo}
        onHide={handleClosePoliceInfo}
        className="modal_type custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="note_update">
              <b>Edit Police Information</b>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="edit_notes_office">
            <Row className="mb-3">
              <Col md={4} className="mb-3">
                <h3 className="note_update">Police Report</h3>
                <div className="date_time_set">
                  <Form.Control required type="text" />
                </div>
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Police Time Zone</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Police Notified On</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Badge#/Name</h3>
                <Form.Control required type="time" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Police Agency</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Police Address</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">City</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">State</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Zip</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Police Branch</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Police Updates E-mail</h3>
                <Form.Control required type="email" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Police Updates Phone</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Police Fax</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Police Toll-free</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Police Transport Notes</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={12} className="mb-3">
                <h3 className="note_update">Police Additional Info</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Create</button>
                </div>
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Cancel</button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showEditStorageInfo}
        onHide={handleCloseStorageInfo}
        className="modal_type custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="note_update">
              <b>Edit Storage Information</b>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="edit_notes_office">
            <Row className="mb-3">
              <Col md={4} className="mb-3">
                <h3 className="note_update">Storage Location</h3>
                <div className="date_time_set">
                  <Form.Control required type="text" />
                </div>
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Address</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={4} className="mb-3">
                <h3 className="note_update">Lot Space Number</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Phone</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={6} className="mb-3">
                <h3 className="note_update">Hours of Operation</h3>
                <Form.Control required type="text" />
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Create</button>
                </div>
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Cancel</button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default RecoveryPageDetails;
