import React, { useRef, useState} from "react";
import Chart from "react-apexcharts";
import "../../CSS/charts.css";
import { ProgressBar } from "react-bootstrap";

const MonhtlyOverview = () => {
  const [currentMonth, setCurrentMonth] = useState(0); 

  const handleNextMonth = () => {
    setCurrentMonth(currentMonth + 1);
  };

  const handlePrevMonth = () => {
    setCurrentMonth(currentMonth - 1);
  };

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const year = 2024;
  const month = months[currentMonth % 12];
  const chartRef = useRef(null);
  const [chartOptions, setChartOptions] = useState({
    series: [70],
    chart: {
      height: 300,
      type: "radialBar",
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: "60%",
          background: "#fff",
          image: undefined,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: "#fff",
          strokeWidth: "25%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: "#888",
            fontSize: "17px",
          },
          value: {
            formatter: function (val) {
              return parseInt(val.toString(), 10).toString();
            },
            color: "#111",
            fontSize: "36px",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#ABE5A1"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Cases Solved"],
  });

  return (
    <div>
      <div className="radial_chart">
        <div className="months_analysis">
          <i className="fa-solid fa-chevron-left" onClick={handlePrevMonth}></i>
          <h2> {month} {year}</h2>
          <i className="fa-solid fa-chevron-right" onClick={handleNextMonth}></i>
        </div>
        <Chart
          options={chartOptions}
          series={chartOptions.series}
          type="radialBar"
          height={250}
          ref={chartRef}
        />
        {/* <div className="resolved_cases_monthly">
          <div
            className="cases_bar_monthly"
          >
            <div>
               Resolved Cases
            </div>
            <div className="divide_monthly">
              <p>28/98</p>
            </div>
          </div>
          <div>
            <ProgressBar now={67} label={`${67}%`} />
          </div>
        </div> */}
        <div className="resolved_cases_monthly">
          <div
            className="cases_bar_monthly"
          >
            <div>Assigned Cases</div>
            <div className="divide_monthly">
              <p>24/76</p>
            </div>
          </div>
          <div>
            <ProgressBar now={43} label={`${43}%`} />
          </div>
        </div>
        <div className="resolved_cases_monthly">
          <div
            className="cases_bar_monthly"
          >
            <div>Approved Cases</div>
            <div className="divide_monthly">
              <p>34/68</p>
            </div>
          </div>
          <div>
            <ProgressBar now={56} label={`${56}%`} />
          </div>
        </div>
        <div className="resolved_cases_monthly">
          <div
            className="cases_bar_monthly"
          >
            <div>Validated Cases</div>
            <div className="divide_monthly">
              <p>48/88</p>
            </div>
          </div>
          <div>
            <ProgressBar now={78} label={`${78}%`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonhtlyOverview;
