import React, { useState } from "react";
import "../CSS/morepage.css";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
import StatusAndOrderTableData from "../Component/statusAndOrdeTable";
import {
  Col,
  Container,
  Form,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
// import AllEditModalDetails from "../Component/allEditModal";
const OfficeNotesPageDetails = () => {
  // const [showEditSection, setShowEditSection] = useState(true);
  const [showEditOffice, setShowEditOffice] = useState(false);

  const handleCloseOfficeNotes = () => setShowEditOffice(false);
  const handleShowOfficeNotes = () => setShowEditOffice(true);
  // const openEditModal = () => {
  //   <AllEditModalDetails />;
  // };

  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <StatusAndOrderTableData />
              <Tabs
                defaultActiveKey="Office Notes"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="Office Notes" title="Office Notes">
                  <div className="case_complaint">
                    <p>Date & Time</p>
                    <p>By</p>
                    <p>Note</p>
                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={handleShowOfficeNotes}
                    ></i>
                  </div>
                  <div className="no_complain">
                    <div className="stacy_call">
                      <p>2024-03-20 12:20PM</p>
                      <p>Charlotte</p>
                      <p>CLIENT WOULD LIKE A CALL </p>
                      <p></p>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="Add New Office Notes"
                  title="Add New Office Notes"
                >
                  <div className="case_complaint">
                    <p>Date & Time</p>
                    <p>By</p>
                    <p>Note</p>
                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={handleShowOfficeNotes}
                    ></i>
                  </div>
                  <div className="no_complain">
                    {/* {showEditSection && ( */}
                    <div id="edit_notes_office">
                      <Row className="mb-3">
                        <Col md={4}>
                          <h3>Date & Time</h3>
                          <div className="date_time_set">
                            <Form.Control required type="date" />
                            <Form.Control required type="time" />
                          </div>
                        </Col>
                        <Col md={8}>
                          <h3>By</h3>
                          <Form.Select aria-label="Default select example">
                            <option>Title Received</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h3>Notes</h3>
                          <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="create_button">
                            <button>Create</button>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="create_button">
                            <button>Cancel</button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {/* )} */}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Container>
        </div>
      </div>
   
      <Modal show={showEditOffice} onHide={handleCloseOfficeNotes} className="modal_type custom-modal">
        <Modal.Header closeButton>
          <Modal.Title><h3 className="note_update"><b>Edit Office Notes</b></h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div id="edit_notes_office">
            <Row className="mb-3">
              <Col md={4} className="mb-3">
                <h3 className="note_update">Date & Time</h3>
                <div className="date_time_set">
                  <Form.Control required type="date" />
                  <Form.Control required type="time" />
                </div>
              </Col>
              <Col md={8} className="mb-3">
                <h3 className="note_update">By</h3>
                <Form.Select aria-label="Default select example">
                  <option>Title Received</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <h3 className="note_update">Notes</h3>
                <Form.Control as="textarea" style={{ height: "100px" }} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="create_button">
                  <button>Create</button>
                </div>
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Cancel</button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default OfficeNotesPageDetails;
