import React from "react";
import "../CSS/table.css";
import { Table } from "react-bootstrap";
const DueOverDataTable = ({ tableRows, headingData, border }) => {
  return (
    <>
      <Table className={border === true ? " table-bordered text-center " : "text-center borderless"} >
        <thead>{headingData}</thead>
        <tbody>{tableRows}</tbody>
      </Table>
    </>
  );
};
export default DueOverDataTable;
