import React from "react";
import "../CSS/morepage.css";
import StatusAndOrderTableData from "./statusAndOrdeTable";
import { Col, Form, Row, Table } from "react-bootstrap";
const InvoiceCaseData = () => {
  return (
    <>
    <div className="status_data12">
      <StatusAndOrderTableData />
    </div>
      <div className="case_complaint">
        <p>Invoice Date</p>
        <p>Approval Date</p>
        <p>BIll To</p>
        <div>
          <i className="fa-solid fa-trash delete_trash"></i>{" "}
          <i className="fa-regular fa-pen-to-square"></i>
        </div>
      </div>
      <div className="no_complain mb-3">
        <div className="date_details1">
        <p>2023-08-20</p>
        <p>2023-08-20</p>
        <p>2023-08-20</p>
        <p></p>
        </div>
        <div>
        <Table borderless>
          <thead>
            <tr>
              <th>Date</th>
              <th>Service Rendered</th>
              <th>Quantity</th>
              <th>Cost</th>
              <th>Taxable</th>
              <th>Tax Rate</th>
              <th>Subtotal</th>
              <th>Expenses</th>
              <th>Profit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2023-09-23</td>
              <td>Voluntary</td>
              <td>1</td>
              <td>$250.00</td>
              <td>No</td>
              <td>N/A</td>
              <td>$250.00</td>
              <td>$0.00</td>
              <td>$250.00</td>
            </tr>
          </tbody>
        </Table>
        <div>
            <p><b>Note:</b>CRYSTAL MARSHALL Year: 2004 Make: Chevrolet Model: ColoradoV.I.N.: 1GCCS196448137504LMA13-7504</p>
        </div>
        </div>
      </div>
      <div className="case_complaint">
        <p>Case Profit Analysis</p>
        <div>
          <i className="fa-solid fa-trash delete_trash"></i>{" "}
          <i className="fa-regular fa-pen-to-square"></i>
        </div>
      </div>
      <div className="no_complain mb-3">
        <Table borderless>
          <thead>
            <tr>
              <th>Invoice</th>
              <th>Total Billing</th>
              <th>Total Expenses</th>
              <th>Total Profit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Count 2</td>
              <td>$250.00</td>
              <td>$0.00</td>
              <td>$250.00</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="case_complaint">
        <p>Fee Approval</p>
        <div>
          <i className="fa-solid fa-trash delete_trash"></i>{" "}
          <i className="fa-regular fa-pen-to-square"></i>
        </div>
      </div>
      <div className="no_complain mb-3">
        <p>There are no fee approval for this case</p>
      </div>
      <div className="case_complaint">
        <p>Add New Payment</p>
        <div>
          <i className="fa-solid fa-trash delete_trash"></i>{" "}
          <i className="fa-regular fa-pen-to-square"></i>
        </div>
      </div>
      <div className="no_complain">
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <h3>Date</h3>
              <Form.Control required type="date" />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <h3>Paid By</h3>
              <Form.Control required type="text" />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <h3>Check#</h3>
              <Form.Control required type="text" />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <h3>Amount</h3>
              <Form.Control required type="text" />
            </div>
          </Col>
          <Col md={6}>
            <div className="create_button">
              <button>Create</button>
            </div>
          </Col>
          <Col md={6}>
            <div className="create_button">
              <button>Cancel</button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="case_complaint">
        <p>Add New Fee Request</p>
        <div>
          <i className="fa-solid fa-trash delete_trash"></i>{" "}
          <i className="fa-regular fa-pen-to-square"></i>
        </div>
      </div>
      <div className="no_complain">
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <h3>Client</h3>
              <Form.Control required type="text" />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <h3>Type</h3>
              <Form.Select aria-label="Default select example">
                <option>Delson</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <h3>Amount</h3>
              <Form.Control required type="text" />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <h3>Description</h3>
              <Form.Control as="textarea" style={{ height: "50px" }} />
            </div>
          </Col>
          <Col md={6}>
            <div className="create_button">
              <button>Create</button>
            </div>
          </Col>
          <Col md={6}>
            <div className="create_button">
              <button>Cancel</button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default InvoiceCaseData;
