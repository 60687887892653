import React, { useState } from "react";
import "../CSS/morepage.css";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
import StatusAndOrderTableData from "../Component/statusAndOrdeTable";
import { Col, Container, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
const ReleasePageDetails = () => {
  const [showEditReleaseInfo, setShowEditReleaseInfo] = useState(false);

  const handleCloseReleaseInfo = () => setShowEditReleaseInfo(false);
  const handleShowReleaseInfo = () => setShowEditReleaseInfo(true);
  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <StatusAndOrderTableData />
              <Tabs
                defaultActiveKey="Release Information"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="Release Information" title="Release Information">
                  <div className="case_complaint">
                    <p>Release Information</p>
                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={handleShowReleaseInfo}
                    ></i>
                  </div>
                  <div className="no_complain">
                    <Row className="mb-3">
                      <Col md={3}>
                        <div>
                          <h3>Disposition</h3>
                          <Form.Select aria-label="Default select example">
                            <option>Stored</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <h3>Storage Location</h3>
                          <p>MEAR-FREDERICKSBURG LOT</p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <h3>Location Address</h3>
                          <p>47 COOL SPRING RD, FREDERICKSBURG, VA 22405</p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <h3>By</h3>
                          <Form.Select aria-label="Default select example">
                            <option>Delson</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                      </Col>
                      <Form.Group as={Col} md="3" className="mb-3">
                        <Form.Label>To</Form.Label>
                        <Form.Select aria-label="Default select example">
                          <option>Stored</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </Form.Group>
                      <Col md={3}>
                        <div>
                          <h3>Transport Date</h3>
                          <Form.Control required type="date" />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <h3>Date Vehical Left Lot</h3>
                          <Form.Control required type="date" />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <h3>Expacted Delivery To Auction</h3>
                          <Form.Control required type="date" />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <h3>Title Received</h3>
                          <Form.Select aria-label="Default select example">
                            <option>Title Received</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <h3>Personal Property Release Date</h3>
                          <Form.Select aria-label="Default select example">
                            <option>Stored</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                      </Col>
                      <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>Storage Bin Number</Form.Label>
                        <Form.Control required type="text" />
                      </Form.Group>
                      <Form.Group as={Col} md="4" className="mb-3">
                        <Form.Label>Transport Notes</Form.Label>
                        <Form.Control
                          as="textarea"
                          style={{ height: "100px" }}
                        />
                      </Form.Group>

                      <Col md={4}>
                        <div>
                          <h3>Release Notes</h3>
                          <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div>
                          <h3>Property Release Notes</h3>
                          <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Tab>
                <Tab eventKey="Transport Request" title="Transport Request">
                  <div className="case_complaint">
                    <p>Transport Request</p>
                    <div>
                      <i className="fa-solid fa-print"></i>
                      <span>Print</span>
                      <i className="fa-regular fa-file-lines"></i>
                      <span>Save PDF</span>
                      <i className="fa-regular fa-envelope"></i>
                      <span>Email</span>
                      <i className="fa-solid fa-fax"></i>
                      <span>Fax</span>
                      <i className="fa-regular fa-floppy-disk"></i>
                      <span>Save Documents</span>
                    </div>
                  </div>
                  <div className="no_complain">
                    <div className="trans_request">
                      <h3>
                        <b>Transport Authorization</b>
                      </h3>
                      <div className="mid_express">
                        <div>
                          <p>Midnight Express Auto Recovery Inc.</p>
                          <p>PO BOX 3351</p>
                          <p>Stafford, VA 22555</p>
                          <p>
                            <b>Phone </b>: 757-445-8698 <b>Fax</b>: 7034459933
                          </p>
                        </div>
                        <div className="date_side">
                          <p>02/19/2024</p>
                          <p>
                            <b>Case#:</b> 2054516978
                          </p>
                          <p>
                            <b>Account#:</b>
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div>
                        <h4>
                          <b>Client</b>
                        </h4>
                        <p>COUNTY AUTO BROKER INC.</p>
                        <p>7006 GEORGE WASHINGTOM MEM. HWY</p>
                        <p>YORKTOWN, VA 23692</p>
                        <p>
                          <b>Phone </b>: 757-445-8698 <b>Fax</b>: 7034459933
                        </p>
                      </div>
                      <hr />
                      <div>
                        <Row>
                          <Col md={9}>
                            <div>
                              <b>Regarding</b>: Jhon Doe
                            </div>
                          </Col>
                          <Col md={3}>
                            <div>
                              <h4>
                                <b>Vehicle</b>
                              </h4>
                              <p>2004 Chevrolet Colorado</p>
                              <p>
                                <b>VIN </b>: A3482C6B5274484A5
                              </p>
                            </div>
                          </Col>
                          <p>
                            : This is your authorization to transport the above
                            vehicle,
                          </p>
                          <p>
                            <b>FROM</b> : MEAR - FREDRICKSBURG LOT 47 COOL
                            SPRING RD FREDERICKSBURG VA 22405
                          </p>
                          <p>
                            <b>To</b>:
                          </p>
                        </Row>
                      </div>
                      <div>
                        <p>Sign and fax back when received. </p>
                        <p>Thank You,</p>
                        <p>
                          <b>Additional Transport Notes:</b>
                        </p>
                      </div>
                      <hr />
                      <div>
                        <Row>
                          <Col md={6}>
                            <div>
                              <b>Signature</b>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>
                              <b>Date</b>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Release Request" title="Release Request">
                  <div className="case_complaint">
                    <p>Release Request</p>
                    <div>
                      <i className="fa-solid fa-print"></i>
                      <span>Print</span>
                      <i className="fa-regular fa-file-lines"></i>
                      <span>Save PDF</span>
                      <i className="fa-regular fa-envelope"></i>
                      <span>Email</span>
                      <i className="fa-solid fa-fax"></i>
                      <span>Fax</span>
                      <i className="fa-regular fa-floppy-disk"></i>
                      <span>Save Documents</span>
                    </div>
                  </div>
                  <div className="no_complain">
                    <div className="trans_request">
                      <h3>
                        <b>Release Authorization</b>
                      </h3>
                      <div className="mid_express">
                        <div>
                          <p>Midnight Express Auto Recovery Inc.</p>
                          <p>PO BOX 3351</p>
                          <p>Stafford, VA 22555</p>
                          <p>
                            <b>Phone </b>: 757-445-8698 <b>Fax</b>: 7034459933
                          </p>
                        </div>
                        <div className="date_side">
                          <p>02/19/2024</p>
                          <p>
                            <b>Case#:</b> 2054516978
                          </p>
                          <p>
                            <b>Account#:</b>
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="client_country">
                        <div>
                          <h4>
                            <b>Client</b>
                          </h4>
                          <p>COUNTY AUTO BROKER INC.</p>
                          <p>7006 GEORGE WASHINGTOM MEM. HWY</p>
                          <p>YORKTOWN, VA 23692</p>
                          <p>
                            <b>Phone </b>: 757-445-8698 <b>Fax</b>: 7034459933
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Lien Holder</b> : COUNTY AUTO BROKER INC.
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div>
                        <Row>
                          <Col md={9}>
                            <div>
                              <p className="m-0">
                                <b>Regarding</b>:
                              </p>
                              <p>John Doe</p>
                              <p>Chevrolet Colorado 2004</p>
                              <p>
                                <b>VIN </b>: A3482C6B5274484A5
                              </p>
                            </div>
                          </Col>
                          <div>
                            <p>
                              <b>Brien White</b>
                            </p>
                            <p>
                              : This is your authorization to transport the
                              above vehicle,
                            </p>
                          </div>
                        </Row>
                      </div>
                      <div>
                        <p>Sign and fax back when received. </p>
                        <p>Thank You,</p>
                      </div>
                      <hr />
                      <div>
                        <Row>
                          <Col md={6}>
                            <div>
                              <b>Signature</b>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>
                              <b>Date</b>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Vehical Receipt" title="Vehical Receipt">
                  <div className="case_complaint">
                    <p>Vehical Receipt</p>
                    <div>
                      <i className="fa-solid fa-print"></i>
                      <span>Print</span>
                      <i className="fa-regular fa-file-lines"></i>
                      <span>Save PDF</span>
                      <i className="fa-regular fa-envelope"></i>
                      <span>Email</span>
                      <i className="fa-solid fa-fax"></i>
                      <span>Fax</span>
                      <i className="fa-regular fa-floppy-disk"></i>
                      <span>Save Documents</span>
                    </div>
                  </div>
                  <div className="no_complain">
                    <div className="trans_request">
                      <h3>
                        <b>Receipt For Redeeming Vehicle</b>
                      </h3>
                      <div className="mid_express">
                        <div>
                          <p>Midnight Express Auto Recovery Inc.</p>
                          <p>PO BOX 3351</p>
                          <p>Stafford, VA 22555</p>
                          <p>
                            <b>Phone </b>: 757-445-8698 <b>Fax</b>: 7034459933
                          </p>
                        </div>
                        <div className="date_side">
                          <p>02/19/2024</p>
                          <p>
                            <b>Case#:</b> 2054516978
                          </p>
                          <p>
                            <b>Account#:</b>
                          </p>
                        </div>
                      </div>
                      <hr />
                      <Row>
                        <Col md={9}>
                          <div>
                            <h4>
                              <b>Client</b>
                            </h4>
                            <p>COUNTY AUTO BROKER INC.</p>
                            <p>7006 GEORGE WASHINGTOM MEM. HWY</p>
                            <p>YORKTOWN, VA 23692</p>
                            <p>
                              <b>Phone </b>: 757-445-8698 <b>Fax</b>: 7034459933
                            </p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <h4>
                              <b>Collateral</b>
                            </h4>
                            <p>2004 Chevrolet Colorado</p>
                            <p>
                              <b>VIN</b>: A3482C6B5274484A5
                            </p>
                            <p>
                              <b>Lien Holder</b> : COUNTY AUTO BROKER INC.
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <div>
                        <p>
                          <b>
                            The following items were recovered from the above
                            vehicle on: 08/22/2017:
                          </b>
                        </p>
                        <p>Trash</p>
                      </div>
                      <div>
                        <Row>
                          <Col md={8}>
                            <p>
                              I, John  Doe, the owner of the above referenced
                              vehicle or the person authorized by the owner of
                              the abovereferenced vehicle, hereby acknowledge
                              receipt of the above mentioned vehicle and
                              personal effects.
                            </p>
                          </Col>
                          <Col md={4}></Col>
                        </Row>
                      </div>
                      <hr />
                      <Row className="text_base">
                        <Col md={9}>
                          <div>
                            <p>Property Notes Here :</p>
                            <p>
                              <b>Storage Per Day:  $ 30.00</b>
                            </p>
                            <p>
                              <b>Number of Days Stored: 2373</b>
                            </p>
                            <p>
                              <b>Handling Fee:     $ 35.00TOTAL </b>
                            </p>
                            <p>
                              <b>DUE:            $ 70,825.</b>
                            </p>
                          </div>
                          <hr />
                          <p>Midnight Express Auto Recovery Inc. Agent</p>
                        </Col>
                        <Col md={3}>
                          <div>
                            <p className="m-0">Date Redeeming:</p>
                            <p>02/19/2024 @22:05:35</p>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <Row className="mb-3">
                        <Col md={6}>
                          <p>
                            <b>John Doe or Authorized Agent</b>
                          </p>
                        </Col>
                        <Col md={6}>
                          <p>
                            <b>Date</b>
                          </p>
                        </Col>
                      </Row>
                      <div>
                        <p>
                          <b>R.O Notes : </b>
                        </p>
                        <div>
                          <hr className="hr_special" />
                        </div>
                        <div>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Delivery Ticket" title="Delivery Ticket">
                  <div className="case_complaint">
                    <p>Delivery Ticket</p>
                    <div>
                      <i className="fa-solid fa-print"></i>
                      <span>Print</span>
                      <i className="fa-regular fa-file-lines"></i>
                      <span>Save PDF</span>
                      <i className="fa-regular fa-envelope"></i>
                      <span>Email</span>
                      <i className="fa-solid fa-fax"></i>
                      <span>Fax</span>
                      <i className="fa-regular fa-floppy-disk"></i>
                      <span>Save Documents</span>
                    </div>
                  </div>
                  <div className="no_complain">
                    <div className="trans_request">
                      <h3>
                        <b>Delivery Order</b>
                      </h3>
                      <div className="mid_express">
                        <div>
                          <p>Midnight Express Auto Recovery Inc.</p>
                          <p>PO BOX 3351</p>
                          <p>Stafford, VA 22555</p>
                          <p>
                            <b>Phone </b>: 757-445-8698 <b>Fax</b>: 7034459933
                          </p>
                        </div>
                        <div className="date_side">
                          <p>02/19/2024</p>
                          <p>
                            <b>Case#:</b> 2054516978
                          </p>
                          <p>
                            <b>Account#:</b>
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="client_country">
                        <div>
                          <h4 className="mb-5">
                            <b>Deliver To</b>
                          </h4>
                          <p>
                            <b>Phone </b>: 757-445-8698 <b>Fax</b>: 7034459933
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Lien Holder</b> : COUNTY AUTO BROKER INC.
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div>
                        <Row>
                          <Col md={6}>
                            <div>
                              <p className="m-0">
                                <b>Debtor</b>
                              </p>
                              <p>John Doe</p>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>
                              <p>
                                <b>Vehical</b>
                              </p>
                              <p>2004 Chevrolet Colorado</p>
                              <p>
                                <b>VIN</b>: A3482C6B5274484A5
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <hr />
                      <div>
                        <h3>
                          <b>***Special Instructions***</b>
                        </h3>
                        <p>Took Keys: Yes - Obtained from customer</p>
                        <p>Took Personal Effects : Yes ( ) No ( ) </p>
                        <h3>
                          <b>***Other Instructions***</b>
                        </h3>
                        <p></p>
                      </div>
                      <hr />
                      <div>
                        <p>
                          THE UNDERSIGNED HEREBY ACKNOWLEDGES THE FOLLOWING
                          ITEM(S) WERE LEFT IN THE UNIT AT THE TIME IT
                          WASDELIVERED TO THEM:
                        </p>
                        <p>
                          <b>Radio</b> AM(  )  FM(  ) CASSETTE(  ) CD PLAYER( 
                          )  SLIDE IN ( ) {" "}
                        </p>
                        <p>
                          AMP(  ) BOOSTER(  )  TELEPHONE(  )  CAMPER TOP(  ) 
                          TELEVISION(  )
                        </p>
                        <p>Other List:</p>
                        <hr />
                      </div>
                      <div>
                        <Row className="mb-3">
                          <Col md={6}>
                            <hr />
                            <p>
                              <b>Signature of Person Receiving Unit</b>
                            </p>
                          </Col>
                          <Col md={3}></Col>
                          <Col md={3}>
                            <hr />
                            <p>
                              <b>Date Received</b>
                            </p>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Col md={6}>
                            <hr />
                            <p>
                              <b>
                                Midnight Express Auto Recovery Inc.
                                AgentDriver-please have person signing for this
                                unitspell his or her name.
                              </b>
                            </p>
                          </Col>
                          <Col md={3}></Col>
                          <Col md={3}>
                            <hr />
                            <p>
                              <b>TimeDelivered AM( ) PM( )</b>
                            </p>
                            <hr />
                            <p>
                              <b>Miles One Way</b>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Container>
        </div>
      </div>
      <Modal
        show={showEditReleaseInfo}
        onHide={handleCloseReleaseInfo}
        className="modal_type custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="note_update">
              <b>Edit Release Information</b>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row >
            <Col md={4} className="mb-3">
              <div>
                <h3 className="note_update">Disposition</h3>
                <Form.Select aria-label="Default select example">
                  <option>Stored</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <div>
                <h3 className="note_update">Storage Location</h3>
                <Form.Control required type="text" />
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <div>
                <h3 className="note_update">Location Address</h3>
                <Form.Control required type="text" />
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <div>
                <h3 className="note_update">By</h3>
                <Form.Select aria-label="Default select example">
                  <option>Delson</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
            </Col>
            <Form.Group as={Col} md="4" className="mb-3">
              <div>
                <h3 className="note_update">To</h3>
                <Form.Select aria-label="Default select example">
                  <option>Stored</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
            </Form.Group>
            <Col md={4} className="mb-3">
              <div>
                <h3 className="note_update">Transport Date</h3>
                <Form.Control required type="date" />
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <div>
                <h3 className="note_update">Date Vehical Left Lot</h3>
                <Form.Control required type="date" />
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <div>
                <h3 className="note_update">Expacted Delivery To Auction</h3>
                <Form.Control required type="date" />
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <div>
                <h3 className="note_update">Title Received</h3>
                <Form.Select aria-label="Default select example">
                  <option>Title Received</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div>
                <h3 className="note_update">Personal Property Release Date</h3>
                <Form.Select aria-label="Default select example">
                  <option>Stored</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
            </Col>
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                <h3 className="note_update">Storage Bin Number</h3>
              </Form.Label>
              <Form.Control required type="text" />
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Label>
                <h4 className="note_update">Transport Notes</h4>
              </Form.Label>
              <Form.Control as="textarea" style={{ height: "50px" }} />
            </Form.Group>

            <Col md={4} className="mb-3">
              <div>
                <h3 className="note_update">Release Notes</h3>
                <Form.Control as="textarea" style={{ height: "50px" }} />
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <div>
                <h3 className="note_update">Property Release Notes</h3>
                <Form.Control as="textarea" style={{ height: "50px" }} />
              </div>
            </Col>
            <Col md={6} >
                <div className="create_button">
                  <button>Create</button>
                </div>
              </Col>
              <Col md={6}>
                <div className="create_button">
                  <button>Cancel</button>
                </div>
              </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ReleasePageDetails;
