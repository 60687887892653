import React from "react";
import "../CSS/morepage.css";
import { Col, Form, Row } from "react-bootstrap";
import EditFormHeader from "./editFormHeader";
import HeaderPage from "./header";
const ClientInfoDetails = () => {
  return (
    <>
      <HeaderPage />
      <div className="p-2">
        <div className="pt-5">
          <EditFormHeader />
        </div>
        <div>
          <div className="case_complaint">
            <p>Client Info</p>
            <i class="fa-solid fa-minus minus_sign"></i>
          </div>
          <div className="no_complain">
            <div className="all_client_data">
            <Row>
              <Col md={6} className="mb-3">
                <div>
                  <h3>
                    Addressing To<span>*</span>
                  </h3>
                  <Form.Control required type="text" />
                </div>
              </Col>
              <Col md={6} className="mb-3">
                <div>
                  <h3>
                    Client Name<span>*</span>
                  </h3>
                  <Form.Control required type="text" />
                </div>
              </Col>
              <Col md={8} className="mb-3">
                <div>
                  <h3>
                    Address<span>*</span>
                  </h3>
                  <Form.Control required type="text" />
                </div>
              </Col>
              <Col md={4} className="mb-3">
                <div>
                  <h3>
                    Client State<span>*</span>
                  </h3>
                  <Form.Control required type="text" />
                </div>
              </Col>
              <Col md={4} className="mb-3">
                <div>
                  <h3>
                    Client City<span>*</span>
                  </h3>
                  <Form.Control required type="text" />
                </div>
              </Col>
              <Col md={4} className="mb-3">
                <div>
                  <h3>
                    Zip<span>*</span>
                  </h3>
                  <Form.Control required type="text" />
                </div>
              </Col>
              <Col md={4} className="mb-3">
                <div>
                  <h3>
                    Country<span>*</span>
                  </h3>
                  <Form.Control required type="text" />
                </div>
              </Col>
              <Col md={4} className="mb-3">
                <div>
                  <h3>
                    Phone<span>*</span>
                  </h3>
                  <Form.Control required type="text" />
                </div>
              </Col>
              <Col md={4} className="mb-3">
                <div>
                  <h3>
                    Fax<span>*</span>
                  </h3>
                  <Form.Control required type="text" />
                </div>
              </Col>
            </Row>
            </div>
          </div>
          <div className="mb-3">
            <Row>
            <Col md={6} className="note_update">
                <div className="create_button">
                  <button>Insert</button>
                </div>
              </Col>
              <Col md={6} className="note_update">
                <div className="create_button">
                  <button>Preview</button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default ClientInfoDetails;
