import React from "react";
import AccordianPageData from "../Component/accordDataBasic";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
import { Container } from "react-bootstrap";
const AssignedCaseDetailsData = () => {
  const accorData = [
    {
      heading: "Adjuster Assigned",
      data: {
        "Adjuster Name": "Andrew Hall",
        "Assigned Date": "2024-01-12",
        "Agent Type": "Company adjuster",
        Phone: "5757564664",
        Status: "Repossesed",
        "Agent Last Login": "2024-01-26 12:00 AM",
        Email: "abc@gamil.com",
        Fax: "<empty>",
      },
    },
    {
      heading: "Adjuster Details",
      data: {
        Address: "8665 E. Hartford Dr",
        City: "New York",
        Country: "USA",
        State: "New York",
        Zip: "565475",
        Cell: "5647384678",
        Phone: "4666656655",
      },
    },
    {
      heading: "View Reminder",
      data: {
        "Date/Time": "2024-01-26 12:00 AM",
        Status: "Viewed at 2024-01-26 12:00 AM",
        Remainder: "New Assignment",
      },
    },
    {
      heading: "View Notes",
      data: {
        Notes: "Mid night express we are not able to find out",
      },
    },
    {
      heading: "View History",
      data: {
        "Date/Time ": "2024-01-26 12:00 AM",
        Action: "adjuster on the duty",
      },
    },
    {
      heading: "Place On Hold",
      data: {
        "Saved To RDN": "2024-01-26 12:00 AM",
        "Last Updated": "by Maria",
        "Update Date/Time": "2024-01-26 12:00 AM",
        Company: "Mid Night Express",
        "Update Type": "(o) Others",
        Details: "REMINDER TO STACEY",
      },
    },
    {
      heading: "Close Agent",
      data: {
        "Date & Time": "2024-01-26 12:00 AM",
        "Recovered At": "2015 COURTHOUSE RDSTAFFORD, VA 22554",
        "Recovered By": "Brian White",
        "Time Zone": "America/New York",
        "Disposition of Collateral": "Stored",
        "Odometer Type": "Digital",
        "Miles on Vehicle": "66353",
      },
    },
  ];
  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <AccordianPageData accorData={accorData} />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
export default AssignedCaseDetailsData;
