import React from "react";
import "../CSS/table.css";
import DueOverDataTable from "../Component/table";
import { Badge, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
const DueOverDuecasesData = () => {
  const rowData = [
    {
      caseNumber: "3745663",
      assignmentType: "Involuntary repo",
      priority: "High",
      debtorName: "John Doe",
      client: "XYZ Corp",
      lienHolder: "ABC Bank",
      vehicleInformation: "Toyota Camry",
      vinNumber: "123456789",
      adjuster: "Alice",
      action: "Edit",
    },
    {
      caseNumber: "4663645",
      assignmentType: "Involuntary repo",
      priority: "Low",
      debtorName: "Jane Smith",
      client: "ABC Inc",
      lienHolder: "DEF Credit Union",
      vehicleInformation: "Honda Accord",
      vinNumber: "987654321",
      adjuster: "Bob",
      action: "Edit",
    },
    {
      caseNumber: "4663645",
      assignmentType: "Involuntary repo",
      priority: "High",
      debtorName: "Cristina",
      client: "ABC Inc",
      lienHolder: "DEF Credit Union",
      vehicleInformation: "Honda Accord",
      vinNumber: "987654321",
      adjuster: "Bob",
      action: "Edit",
    },
    {
      caseNumber: "3745663",
      assignmentType: "Involuntary repo",
      priority: "Low",
      debtorName: "Dwane Smith",
      client: "ABC Inc",
      lienHolder: "DEF Credit Union",
      vehicleInformation: "Honda Accord",
      vinNumber: "987654321",
      adjuster: "Bob",
      action: "Edit",
    },
    {
      caseNumber: "7563642",
      assignmentType: "Involuntary repo",
      priority: "Low",
      debtorName: "Warner David",
      client: "ABC Inc",
      lienHolder: "DEF Credit Union",
      vehicleInformation: "Honda Accord",
      vinNumber: "987654321",
      adjuster: "Bob",
      action: "Edit",
    },
  ];

  const headingData = (
    <tr>
      <th>Case Number</th>
      <th>Assignment Type</th>
      <th>Priority</th>
      <th>Debtor Name</th>
      <th>Client</th>
      <th>Lien Holder</th>
      <th>Vehicle Information</th>
      <th>VIN Number</th>
      <th>Adjuster</th>
      <th>Action</th>
    </tr>
  );

  const tableRows = rowData.map((caseItem, index) => (
    <tr key={index}>
      <td>{caseItem.caseNumber}</td>
      <td>
        <div class="select-dropdown">
          <select className="option_like">
            <option value="Option 1">Involuntary repo</option>
            <option value="Option 2">2nd Option</option>
            <option value="Option 3">Option Number</option>
          </select>
        </div>
      </td>

      <td>
        <h4>
          <Badge className={caseItem.priority === "High" ? "high" : "highLow"}>
            {caseItem.priority}
          </Badge>
        </h4>
      </td>
      <td>{caseItem.debtorName}</td>
      <td>{caseItem.client}</td>
      <td>{caseItem.lienHolder}</td>
      <td>{caseItem.vehicleInformation}</td>
      <td>{caseItem.vinNumber}</td>
      <td>{caseItem.adjuster}</td>
      <td>
        <NavLink to={"/due-over-case-details"}>
          <h4 style={{ backgroundColor: "#94C3DB", borderRadius: "4px" }}>
            <Badge bg="#0876AC">
              <i className="fa-regular fa-eye"></i>
            </Badge>
          </h4>
        </NavLink>
      </td>
    </tr>
  ));

  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <DueOverDataTable
                tableRows={tableRows}
                headingData={headingData}
                border={true}
              />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
export default DueOverDuecasesData;
