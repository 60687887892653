import React from "react";
import AccordianPageData from "../Component/accordDataBasic";
import SidebarPage from "../Component/Sidebar";
import HeaderPage from "../Component/header";
import { Container } from "react-bootstrap";
const ApprovedCaseDetailsDatas = () => {
  const accorData = [
    {
      heading: "Client Details",
      data: {
        "Client Phone": "7758777757",
        "Client Fax": "<empty>",
        "Coll Phone": "<empty>",
        "Coll Ext": "<empty>"
      }
    },
    {
      heading: "Debtors Details",
      data: {
        Debtors: "John Due",
        "Debtors SSN": "<empty>",
        "Debtors DOB": "1998-03-30",
        Cosigner: "allan",
        "Cosigner SSN": "<empty>",
        "Cosigner DOB": "<empty>",
        "Past Due": "<empty>",
        Balance: "$95873"
      }
    },
    {
      heading: "Vehicle Details",
      data: {
        Year: "2024",
        Make: "MBW",
        Model: "5-Series",
        Vin: "466465675",
        Color: "Blue",
        Plate: "ABC 123",
        "Tag Expire": "2025-04-21",
        mileage: "54KM"
      }
    },
    {
      heading: "Assign To",
      data: {
        Office: "Mid night express",
        Branch: "unassigned",
        "RDN pro Branch": "N/A",
        "Order Date": "2024-04-21",
        "Case Worker": "Alexander",
        "Case Investigator": "SamSon"
      }
    },
    {
      heading: "Adjusters Information",
      data: {
        "Adjusters Name": "Andrew Tait",
        "Assigned Date": "2024-03-23",
        "Agent Type": "Company Adviser",
        Phone: "9874756567",
        Status: "Reprocessed",
        "Agent Last Login": "2024-01-26 12:00 AM",
        Email: "absc@gmail.com",
        Fax: "<empty>",
      }
    },
    {
      heading: "Update",
      data: {
        "Saved To RDN": "2024-01-26 12:00 AM",
        "Last Updated": "by Maria",
        "Update Date/Time": "2024-01-26 12:00 AM",
        Company: "Mid Night Express",
        "Update Type": "(o) Others",
        Details: "REMINDER TO STACEY"
      }
    },
    {
      heading: "Approval Details",
      data: {
        "Date & Time": "2024-01-26 12:00 AM",
        "Approved By": "Stacey",
        "Status": "Approved",
      }
    },
  ];
  return (
    <>
      <div className="main_wrapper d-flex ">
        <SidebarPage />
        <div className="wrapper">
          <HeaderPage />
          <Container fluid>
            <div className="view_port_section">
              <AccordianPageData accorData={accorData} />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
export default ApprovedCaseDetailsDatas;
